import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-telechargement',
  templateUrl: './telechargement.component.html',
  styleUrls: ['./telechargement.component.scss']
})
export class TelechargementComponent implements OnInit {
  epreuve : string = "";
  resultat : string = "";
  retour : string = "";
  texteRetour : string = "";
  constructor(private router: Router , private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.forEach((urlParams) => {
      this.epreuve= urlParams['epreuve'];
      this.resultat=urlParams['resultat'];
      if(this.resultat=="OK"){
        this.retour = "home";
        this.texteRetour = "Continuer";
      }else{this.retour = this.epreuve;
            this.texteRetour = "Réessayer";
            }

    });
  }
suite(){
  { this.router.navigateByUrl(this.retour);}
}
 
}
