import { Component, OnInit, NgModule, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';



@Component({
  selector: 'app-indice-une-image',
  templateUrl: './indice-une-image.component.html',
  styleUrls: ['./indice-une-image.component.scss']
})




export class IndiceUneImageComponent implements OnInit {
  urlindice : string ;
  urlAudio1 : string ;
  urlAudio2 : string ;
  urlAudio3 : string ;
  epreuve = '';
  public audio1: boolean = false;
  public audio2: boolean = false;
  public audio3: boolean = false;

  constructor(private realmInterface: RealmInterfaceService, actRoute: ActivatedRoute, private router: Router) {
    this.epreuve = actRoute.snapshot.params.epreuve;
    this.audio1 = false;
    this.audio2 = false;  // @PAPA : Est ce normal que audio2 et 3 ne passent jamais à true ???
    this.audio3 = false;

    if (this.epreuve == "youtube") { this.audio1 = true; };

    if (this.epreuve == "wikipedia") { this.audio1 = true; };

  }

  ngOnInit(): void {
    this.urlindice  = this.donneUrl();
    this.urlAudio1 = this.donneUrlAudio('1');
    this.urlAudio2 = this.donneUrlAudio('2');
    this.urlAudio3 = this.donneUrlAudio('3');
  }

  public donneUrl(): string {
    let currenthackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      currenthackerCouleur = localStorage.getItem('hackerCouleur2');
    }

    return `assets/${this.epreuve}/${this.epreuve}${currenthackerCouleur}.png`;

  }

  public donneUrlAudio(num: string): string {
    let currenthackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      currenthackerCouleur = localStorage.getItem('hackerCouleur2');
    }

    return `assets/${this.epreuve}/${this.epreuve}${currenthackerCouleur}${num}.mp3`;

  }
  public donneUrlChapeau(): string {
    let currenthackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      currenthackerCouleur = localStorage.getItem('hackerCouleur2');
    }
    return `assets/chapeau${currenthackerCouleur}.png`;
  }

  public donneUrlLogo(): string {
    return `assets/logo${this.epreuve}.png`;
  }
}
