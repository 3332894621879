<app-entete indiceMode="true" [epreuve]="this.epreuve"></app-entete>

 <div>
<!--<img id="background" src="assets/background.JPG"  border="0" usemap="#html"/> -->
 <div id="iddivimageindice" class="divimageindice" >   

<img  id="imgindice"  src="{{urlindice}}"   border="0"  >

 </div>   
<div *ngIf="audio1">
    <audio  id=audioepreuve src="{{urlAudio1}}" width=220  height=140 controls >  </audio> 
</div>
<div *ngIf="audio2"> 
    <audio  id=audioepreuve src="{{urlAudio2}}" width=220  height=140 controls >  </audio> 
</div>
<div *ngIf="audio3"> 
    <audio  id=audioepreuve src="{{urlAudio3}}" width=220  height=140 controls >  </audio> 
</div>
</div>  