import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { GoogleComponent } from './google/google.component';
import { BabbelComponent } from './babbel/babbel.component';
import { NetflixComponent } from './netflix/netflix.component';
import { IndiceGoogleNoirComponent } from './indice-google-noir/indice-google-noir.component';
import { IndiceUneImageComponent } from './indice-une-image/indice-une-image.component';
import { AllocineComponent } from './allocine/allocine.component';
import { TwitchComponent } from './twitch/twitch.component';
import { SpotifyComponent } from './spotify/spotify.component';
import { IndiceDeuxImagesComponent } from './indice-deux-images/indice-deux-images.component';
import { MarmitonComponent } from './marmiton/marmiton.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { IndicedeuxvideosComponent } from './indicedeuxvideos/indicedeuxvideos.component';
import { VoiciComponent } from './voici/voici.component';
import { WikipediaComponent } from './wikipedia/wikipedia.component';
import { ScoreComponent } from './score/score.component';
import { AccueilComponent } from './accueil/accueil.component';
import { TelechargementComponent } from './telechargement/telechargement.component';
import { EnteteComponent } from './entete/entete.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RealmInterfaceService } from './realm-interface.service';
import { AuthGuard } from './authgard.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GoogleComponent,
    BabbelComponent,
    NetflixComponent,
    IndiceGoogleNoirComponent,
    IndiceUneImageComponent,
    AllocineComponent,
    TwitchComponent,
    SpotifyComponent,
    IndiceDeuxImagesComponent,
    MarmitonComponent,
    YoutubeComponent,
    IndicedeuxvideosComponent,
    VoiciComponent,
    WikipediaComponent,
    ScoreComponent,
    AccueilComponent,
    TelechargementComponent,
    EnteteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule
  ],
  providers: [RealmInterfaceService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
