<div id="wholeComponent" fxLayout="column">
   <div id=barent fxLayout="row" fxLayoutAlign="space-between center">
      <p class="texteBouton" (click)="quiter()">Quiter</p>
      <p class="texteBouton" (click)="score()">Score</p>
      <div id=divChrono class="chronometre">
         <div class="tim">
            <span>{{h}}h</span> :
            <span>{{mn}}m</span> :
            <span>{{s}}s</span>
         </div>
      </div>
      <img id="home" src="assets/Profil.png"/>
   </div>
   <div class="corps">
      <div id="table-contenu">

         <div class="div-categoryContainer"  id="part1">

            <img src="assets/home/Hack Chicken LogoReduite.png" class="picture-left"
               style="width: 250px; height: 250px;">

            <div class="paragraph-right">

               <p class="texteHome">Je n’ai jusqu’à maintenant, eu besoin de personne pour pirater les plus grands
                  sites Internet. J’ai récupéré leurs DATA et j’ai ainsi pu les
                  vendre à tout type d’organisations. Cependant il y a 10 grandes enseignes que je n’ai toujours pas
                  réussi à hacker en entier : leurs sites web
                  demandent des compétences simultanées différentes et très précises.
               </p>
               <p></p>
               <p></p>
               <p class="texteHome">C’est pourquoi j’ai fait appel aux plus grands pour me venir en aide, je vous ai
                  choisis parce que vous êtes les meilleurs dans votre spécialité
               </p>

            </div>
         </div>
         <div fxLayout="row"  fxLayoutAlign="space-around center"  id="part2">
            <div class="divContainerUnChapeau">
               <div>
                  <img src="assets/chapeaunoir.png" class="imgChapeau">

                  <div class="texteChapeau">

                     <p><strong>BLACK HAT</strong><br>Même si nos méthodes sont similaires,lui, il fait ça uniquement
                        pour nuire à autrui. La méchanceté incarnée est en lui, mais je reconnais son génie.</p>

                  </div>
               </div>
               <div class="texteJoueur">

                  <table class="tablejoueurs" border="0" >
                     <tr *ngFor="let joueur of  tabJoueurs2[0] ">
                        <td style="width:100px;font-size: 12pt;"> <a class="tjoueurs"
                              [ngStyle]="{'color':this.tabJoueursCouleur[0]} "> <strong>{{joueur}}</strong> </a>
                        </td>
                     </tr>
                  </table>


               </div>
            </div> <!-- fin container un chapeau  -->
            <div class="divContainerUnChapeau">
               <div>
                  <img src="assets/chapeaugris.png" class="imgChapeau">

                  <div class="texteChapeau">

                     <p><strong>GREY HAT</strong><br>L’homme que l’on appelle aussi Caméléon. Il use de ses
                        compétences aussi bien pour protéger que pour pirater certaines compagnies.</p>

                  </div>
               </div>
               <div class="texteJoueur">

                  <table class="tablejoueurs" border="0">
                     <tr *ngFor="let joueur of  tabJoueurs2[1] ">
                        <td style="width:100px;font-size: 12pt;"> <a class="tjoueurs"
                              [ngStyle]="{'color':this.tabJoueursCouleur[1]} "> <strong>{{joueur}}</strong> </a>
                        </td>
                     </tr>
                  </table>

                  <!--   <p  [ngStyle]="{'color': this.tabJoueursCouleur[1]}"><strong >{{this.tabJoueurs[1]}}</strong>    </p> -->


               </div>
            </div> <!-- fin container un chapeau  -->
            <div class="divContainerUnChapeau">
               <div>
                  <img src="assets/chapeaublanc.png" class="imgChapeau">

                  <div class="texteChapeau">

                     <p><strong>WHITE HAT</strong><br>Le meilleur dans le domaine de la sécurité, il a protégé les
                        plus grands, comme les Services Secrets britanniques ou la CIA.</p>

                  </div>
               </div>
               <div class="texteJoueur">

                  <table class="tablejoueurs" border="0">
                     <tr *ngFor="let joueur of  tabJoueurs2[2] ">
                        <td style="width:100px;font-size: 12pt;"> <a class="tjoueurs"
                              [ngStyle]="{'color':this.tabJoueursCouleur[2]} "> <strong>{{joueur}}</strong> </a>
                        </td>
                     </tr>
                  </table>


               </div>
            </div> <!-- fin container un chapeau  -->
            <div class="divContainerUnChapeau">
               <div>
                  <img src="assets/chapeaurouge.png" class="imgChapeau">

                  <div class="texteChapeau">

                     <p><strong>RED HAT</strong><br>Avec ses méthodes peu orthodoxes, il est jusqu’à aujourd’hui la
                        bête noire des ONG. Il a révélé au grand jour bon nombre d’affaires qui avaient été enfouies
                        sous terre.</p>

                  </div>
               </div>
               <div class="texteJoueur">

                  <table class="tablejoueurs" border="0">
                     <tr *ngFor="let joueur of  tabJoueurs2[3] ">
                        <td style="width:100px;font-size: 12pt;"> <a class="tjoueurs"
                              [ngStyle]="{'color':this.tabJoueursCouleur[3]} "> <strong>{{joueur}}</strong> </a>
                        </td>
                     </tr>
                  </table>


               </div>
            </div> <!-- fin container un chapeau  -->
         </div> <!-- fin div-table-chapeaux   -->
         <div id=nomEquipe class="texteEquipe">


            <p><strong>{{this.eNomEquipe}}</strong></p>

         </div>

         <div class="texte2"  id="part4">


            <p>En passant par cette interface, vous verrez que j’ai caché à l’intérieur de chacun des 10 sites, les
               instructions et les indices que j’ai récupérés. Vous devrez trouver le lien qui vous est destiné puis
               entrez votre code personnel pour les télécharger. Ensuite, à l’aide de l’ensemble de vos compétences
               complémentaires, vous devrez trouver et entrer les codes nécessaires pour récupérer les DATA de chacun
               de ces 10 sites.</p>

         </div>

         <div id="epreuves">
            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[0].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/marmiton.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.HUIT)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>

            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[1].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/twitch.png" 
                     (click)="this.onClickEpreuve(eNomEpreuve.NEUF)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>
            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[2].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/wikipedia.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.SIX)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>
            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[3].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/netflix.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.TROIS)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>
            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[4].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/spotify.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.CINQ)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>
            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[5].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/allocine.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.DIX)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>
            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[6].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/babbel.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.DEUX)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>
            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[7].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/youtube.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.SEPT)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>
            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[8].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/google.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.UN)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>


            <div class="epreuves">

               <div class="DivImageEpreuve"
                  [ngStyle]="{'border-width':(this.tabEpreuves[9].statut === 'X') ? '3px' : '0px' }">
                  <img class="imgEpreuve" src="/assets/logosepreuves/voici.png"
                     (click)="this.onClickEpreuve(eNomEpreuve.QUATRE)" />
               </div>
               <!--   <div class = "topEpreuve">{{this.tabEpreuves[0].statut}}</div> -->
            </div>
         </div>
         <div class="texteRouge" id="part6">

            <p><strong>A VOUS DE JOUER!</strong></p>

         </div>

      </div> <!-- fin table contenu   -->
   </div>
</div>