import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { RealmInterfaceService } from './realm-interface.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private realmInterface: RealmInterfaceService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const nomjoueur = localStorage.getItem('hackerNom');
    const nomequipe = localStorage.getItem('hackerEquipe');
    if (nomjoueur === null || nomequipe === null) {
      this.router.navigate(['/accueil'], {
        queryParams: { returnUrl: state.url },
      });
      return Promise.resolve(false);
    }
    return this.realmInterface
      .isJoueurActif(nomjoueur, nomequipe)
      .then((res) => {
        if (res === false) {
          this.router.navigate(['/accueil'], {
            queryParams: { returnUrl: state.url },
          });
          return Promise.resolve(false);
        }
        return Promise.resolve(true);
      })
      .finally();
  }
}
