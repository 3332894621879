import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Partie } from '../models/partie.model';
import { Equipe , Epreuve } from '../models/partie.model';
import { LeJoueur } from '../models/partie.model';

@Component({
  selector: 'app-marmiton',
  templateUrl: './marmiton.component.html',
  styleUrls: ['./marmiton.component.scss']
})
export class MarmitonComponent implements OnInit {

  ratioImage = 0.8;
  code : string = "saisir le code";
  lapartie : Partie ;
  lejoueur : LeJoueur = {"nom_joueur":"" ,"partie":"","equipe":"", "couleur":"", "mot_de_passe":"", "statut":""};

  constructor(private router: Router , private realmInterface: RealmInterfaceService) {
    localStorage.setItem('epreuve', 'marmiton');
   }


  ngOnInit(): void {
    /*
    this.realmInterface.lapartie.subscribe((lapartie: Partie) => {
            this.lapartie = lapartie;
            console.log(`la partie : ${lapartie.nom_partie}`);
         });
         this.lejoueur.couleur = localStorage.getItem('hackerCouleur');
         this.lejoueur.nom_joueur = localStorage.getItem('hackerNom');
         this.lejoueur.equipe = localStorage.getItem('hackerEquipe') ;
         this.lejoueur.partie = localStorage.getItem('hackerPartie');
     //    this.lejoueur.mot_de_passe = localStorage.getItem('hackerMdP');
    */  
      
  }
  /*
  public donneUrlChapeau(): string
  {
     
     var   hackerCouleur  = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
      return `assets/chapeau${hackerCouleur}.png`;
  }


  public donneUrlLogo(): string
  {
    let epreuve = localStorage.getItem('epreuve');
      return `assets/logo${epreuve}.png`;
    }
    */
    afficherIndice(joueur : string)
    {
      let hackerCouleur = localStorage.getItem('hackerCouleur');
      if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
        hackerCouleur = localStorage.getItem('hackerCouleur2');}
      let url = `indicedeuximages/marmiton`;
      if(joueur == hackerCouleur) {this.router.navigateByUrl(url);}
    }
    /*
    backtohome()  { this.router.navigateByUrl("home");}

    validerCode( event: any){
      console.log(`on a frappe : ${event.which} code :/${event.target.value}/` );
      let cd = event.target.value;
    if(event.which === 13){
    { 
       let lesepr : Epreuve[]  = this.lapartie.les_epreuves;
       let trouve : string = "KO";
      for( let i= 0; i < lesepr.length; i++)
  {
       if(lesepr[i].nom_epreuve == localStorage.getItem('epreuve'))
       {
         if(lesepr[i].code == cd  ) 
         { 
         //cd.value = "BRAVO!!"; 
         console.log(`validerCode : ${lesepr[i].nom_epreuve} i : ${ i }` );
         this.toperEpreuveOK(lesepr[i].nom_epreuve);
         trouve = "OK";
         
         }
        
        }
      } 
  }
   
  this.router.navigate(['telechargement', {epreuve:localStorage.getItem('epreuve'),resultat:trouve}]);
  }
  }
toperEpreuveOK(epr : string)
{
  console.log(`toperEpreuveOK : ${epr}  `);
  this.realmInterface.setEtatEpreuve( epr , localStorage.getItem('hackerEquipe') , "reussie").then((ret : boolean)  => {
    // if(ret) {
     if(true) {
       console.log(`pas de probleme dans set statut : ${ret}  `);
        }
     else {console.log(`probleme dans set statut : ${ret}  `);}
     
     }); 
}
*/
}
