 
<style>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
    </style>

<app-entete epreuve="google"></app-entete> 
<div id="contenu">
    <p class = "txtNormal" > fr.chickengoogle.org › Le-voyage-distanciel</p>  
    <p class = "txtTitre" >Le voyage distanciel</p>
    <p class = "txtNormal" >Votre mission est de trouver le code pour télécharger les DATA. Pour cela ouvrez vos indices et communiquez
afin de mettre en commun les informations.
Vous avez bien sûr le droit d’utiliser tous les sites Internet que vous souhaitez y compris Google Maps et surtout
« Street View ». Si j’ai bien compris, le code est un numéro d’habitation qui se trouve quelque part en France, à
vous de trouver la ville, la rue puis l’habitation et enfin zoomez sur le numéro pour pouvoir le lire. </p>
<p class = "txtUrl" > fr.chickengoogle.org › chapeau-gris  </p>

<p class = "txtTitre"  (click)="afficherIndice('gris')" >Chapeau gris </p>
<p class = "txtNormal" > Au Chapeau Gris, restaurant traditionnel haut de gamme à Versailles. Cuisine raffinée, carte des vins de plus de
360 références, vivier de fruits de mer, foie gras ...</p>
<p class = "txtUrl" >fr.chickengoogle.org › chapeau-noir</p>
<p class = "txtTitre" (click)="afficherIndice('noir')" >Chapeau noir </p>
<p class = "txtNormal" > Un grand choix de chapeaux noirs chez Bon Clic Bon Genre, à porter en toutes occasions. Retrouvez tous nos
modèles sur notre site.</p>
<p class = "txtUrl" > fr.chickengoogle.org › chapeau-blanc</p>
<p class = "txtTitre" (click)="afficherIndice('blanc')" >Chapeau blanc </p>
<p class = "txtNormal" > Un choix unique de chapeaux blancs disponibles dans notre magasin. Codes promo, ventes flash, livraison
offerte, trouvez le produit de vos rêves à prix réduit !</p>
<p class = "txtUrl" >fr.chickengoogle.org › chapeau-rouge</p>
<p class = "txtTitre" (click)="afficherIndice('rouge')" >Chapeau rouge </p>
<p class = "txtNormal" > Le restaurant Le Chapeau Rouge vous propose une cuisine avec le concept du locavore, du terroir, du
biologique. Venez goûter à notre cuisine locavore qui se ...</p>
<p class = "txtUrl" >www.hackchicken.fr › conseils › tout-sur-les-poules</p>
<p class = "txtTitre" >Poules : photos et profils </p>
<p class = "txtNormal" > Avec plus de 30 variétés de poules, vous trouverez forcément celle qui vous correspond ! cadre photo de famille
poules. picto poule pondeuse Nous avons ...</p>
<div id="divpagination">
    <span >   <img id="pagination" src="assets/google/Pagination.png"  border="0"  /> </span>
</div>
</div>