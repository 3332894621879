import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Partie } from '../models/partie.model';
import { Equipe , Epreuve} from '../models/partie.model';
import { LeJoueur } from '../models/partie.model';

@Component({
  selector: 'app-babbel',
  templateUrl: './babbel.component.html',
  styleUrls: ['./babbel.component.scss']
})
export class BabbelComponent implements OnInit {
  imageIndiceVisible : boolean =  false ;
   
  imgIndice  : HTMLDivElement  ;
  constructor(private router: Router , private realmInterface: RealmInterfaceService) {
    localStorage.setItem('epreuve', 'babbel');
   }

  ngOnInit(): void {
    
      
  }
  afficherIndice(joueur : string)
  {
    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
    let url = `indicedeuximages/babbel`;
    let mess = `joueur : ${joueur} hacker = ${hackerCouleur}`;
    console.log(mess);
    if(joueur == hackerCouleur) {this.router.navigateByUrl(url);}
  }
  
       afficherIndiceTous(){
        if(this.imageIndiceVisible){
        //this.imgIndice.style.display = "none";
        this.imageIndiceVisible = false;
      }else{
        //this.imgIndice.style.display = "block";
        this.imageIndiceVisible = true;
      }
         
         
       }
        
}
