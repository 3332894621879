import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { HomeComponent } from './home/home.component';
import { GoogleComponent } from './google/google.component';
import { BabbelComponent } from './babbel/babbel.component';
import { SpotifyComponent } from './spotify/spotify.component';
import { AllocineComponent } from './allocine/allocine.component';
import { TwitchComponent } from './twitch/twitch.component';
import { MarmitonComponent } from './marmiton/marmiton.component';
import { NetflixComponent } from './netflix/netflix.component';
import { VoiciComponent } from './voici/voici.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { WikipediaComponent } from './wikipedia/wikipedia.component';
import { IndiceGoogleNoirComponent } from './indice-google-noir/indice-google-noir.component';
import { IndiceUneImageComponent } from './indice-une-image/indice-une-image.component';
import { IndiceDeuxImagesComponent } from './indice-deux-images/indice-deux-images.component';
import { IndicedeuxvideosComponent } from './indicedeuxvideos/indicedeuxvideos.component';
import { TelechargementComponent } from './telechargement/telechargement.component';
import { ScoreComponent } from './score/score.component';
import { AuthGuard } from './authgard.service';

const routes: Routes = [
  {path: 'accueil', component: AccueilComponent},
  {path: '',   redirectTo: '/accueil', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'google', component: GoogleComponent, canActivate: [AuthGuard]},
  {path: 'babbel', component: BabbelComponent, canActivate: [AuthGuard]},
  {path: 'spotify', component: SpotifyComponent, canActivate: [AuthGuard]},
  {path: 'allocine', component: AllocineComponent, canActivate: [AuthGuard]},
  {path: 'twitch', component: TwitchComponent, canActivate: [AuthGuard]},
  {path: 'marmiton', component: MarmitonComponent, canActivate: [AuthGuard]},
  {path: 'netflix', component: NetflixComponent, canActivate: [AuthGuard]},
  {path: 'voici', component: VoiciComponent, canActivate: [AuthGuard]},
  {path: 'youtube', component: YoutubeComponent, canActivate: [AuthGuard]},
  {path: 'wikipedia', component: WikipediaComponent, canActivate: [AuthGuard]},
  {path: 'indice-google-noir', component: IndiceGoogleNoirComponent, canActivate: [AuthGuard]}, // @PAPA : Cette route semble inutilisée. Si c'est vrai on la vire ainsi que son composant.
  {path: 'indiceuneimage/:epreuve', component: IndiceUneImageComponent, canActivate: [AuthGuard]},
  {path: 'indicedeuximages/:epreuve', component: IndiceDeuxImagesComponent, canActivate: [AuthGuard]},
  {path: 'indicedeuxvideos/:epreuve', component: IndicedeuxvideosComponent, canActivate: [AuthGuard]},
  {path: 'telechargement', component: TelechargementComponent, canActivate: [AuthGuard]},
  {path: 'telechargement/:epreuve/:resultat',component: TelechargementComponent, canActivate: [AuthGuard]},
  {path: 'score', component: ScoreComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
