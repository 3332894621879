import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createDocumentRegistry } from 'typescript';
import { RealmInterfaceService } from '../realm-interface.service';
import { Epreuve } from '../models/partie.model';
import { Partie } from '../models/partie.model';
import { Equipe } from '../models/partie.model';
import { LeJoueur } from '../models/partie.model';

@Component({
  selector: 'app-allocine',
  templateUrl: './allocine.component.html',
  styleUrls: ['./allocine.component.scss']
})
export class AllocineComponent implements OnInit {
 // ratioImage = 0.8;
 // code : string = "saisir le code";
//  lapartie : Partie ;
//  lejoueur : LeJoueur = {"nom_joueur":"" ,"partie":"","equipe":"", "couleur":"", "mot_de_passe":"", "statut":""};
  constructor(private router: Router , private realmInterface: RealmInterfaceService) {
    localStorage.setItem('epreuve', 'allocine');
   }

  ngOnInit(): void {
/*
    this.realmInterface.lapartie.subscribe((lapartie: Partie) => {
      this.lapartie = lapartie;
       
   });
   this.lejoueur.couleur = localStorage.getItem('hackerCouleur');
   this.lejoueur.nom_joueur = localStorage.getItem('hackerNom');
   this.lejoueur.equipe = localStorage.getItem('hackerEquipe') ;
   this.lejoueur.partie = localStorage.getItem('hackerPartie');
   //this.lejoueur.mot_de_passe = localStorage.getItem('hackerMdP');
   if(this.realmInterface.getJoueurEnCours()!=0){
    this.lejoueur.couleur = localStorage.getItem('hackerCouleur2');
    this.lejoueur.nom_joueur = localStorage.getItem('hackerNom2');
    //this.lejoueur.mot_de_passe = localStorage.getItem('hackerMdP2');
   };
  // this.realmInterface.leJoueur.subscribe(joueur => {
   // if (joueur !== undefined) { this.lejoueur = joueur; }
   //   })  
  */    
  }
  /*
  validerCode( event: any){
    console.log(`on a frappe : ${event.which} code :/${event.target.value}/` );
    let cd = event.target.value;
  if(event.which === 13){
  { 
     let lesepr : Epreuve[]  = this.lapartie.les_epreuves;
     let trouve : string = "KO";
    for( let i= 0; i < lesepr.length; i++)
{
     if(lesepr[i].nom_epreuve == localStorage.getItem('epreuve'))
     {
       if(lesepr[i].code == cd  ) 
       { 
       //cd.value = "BRAVO!!"; 
       console.log(`validerCode : ${lesepr[i].nom_epreuve} i : ${ i }` );
       this.toperEpreuveOK(lesepr[i].nom_epreuve);
       trouve = "OK";
       
       }
      
      }
    } 
}
 
this.router.navigate(['telechargement', {epreuve:localStorage.getItem('epreuve'),resultat:trouve}]);
}
}


toperEpreuveOK(epr : string)
{
  console.log(`toperEpreuveOK : ${epr}  `);
  this.realmInterface.setEtatEpreuve( epr , localStorage.getItem('hackerEquipe') , "reussie").then((ret : boolean)  => {
    // if(ret) {
     if(true) {
       console.log(`pas de probleme dans set statut : ${ret}  `);
        }
     else {console.log(`probleme dans set statut : ${ret}  `);}
     
     }); 
}
*/
  afficherIndice(joueur : string)
  {
    let   hackerCouleur  = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
    let url = `indiceuneimage/allocine`;
    if(joueur == hackerCouleur) {this.router.navigateByUrl(url);}
  }
  /*
  backtohome()  { this.router.navigateByUrl("home");}
  
  public donneUrlChapeau(): string
  {
     
     let   hackerCouleur  = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
      return `assets/chapeau${hackerCouleur}.png`;
  }
*/
  public donneUrlLogo(): string
  {
    let epreuve = localStorage.getItem('epreuve');
      return `assets/logo${epreuve}.png`;
    }
/*
    onKeypressEvent(event: any){
      console.log(event.which );
      console.log(event.target.value);
   
   }
*/
  }
