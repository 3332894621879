import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Partie } from '../models/partie.model';

@Component({
  selector: 'app-indice-deux-images',
  templateUrl: './indice-deux-images.component.html',
  styleUrls: ['./indice-deux-images.component.scss']
})
export class IndiceDeuxImagesComponent implements OnInit {
  epreuve = '';
  lapartie: Partie;
  urlindice1 : string ;
  urlindice2 : string ;
  constructor(private router: Router, actRoute: ActivatedRoute, private realmInterface: RealmInterfaceService) { 
    this.epreuve = actRoute.snapshot.params.epreuve;
  }

  ngOnInit(): void {
    this.realmInterface.lapartie.subscribe((lapartie: Partie) => {
      this.lapartie = lapartie;

    });
    this.urlindice1  = this.donneUrl("1");
    this.urlindice2  = this.donneUrl("2");
  }
  public donneUrlChapeau(): string {

    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');
    }
    return `assets/chapeau${hackerCouleur}.png`;
  }

  public donneUrlLogo(): string {
    let epreuve = localStorage.getItem('epreuve');
    return `assets/logo${epreuve}.png`;
  }
  public donneUrl(num: string): string {
    let epreuve = localStorage.getItem('epreuve');
    let currenthackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      currenthackerCouleur = localStorage.getItem('hackerCouleur2');
    }
    console.log(`assets/${this.epreuve}/${this.epreuve}${currenthackerCouleur}${num}.png`);
    return `assets/${this.epreuve}/${this.epreuve}${currenthackerCouleur}${num}.png`;

  }
  public donneUrl2(): string {
    let epreuve = localStorage.getItem('epreuve');
    let currenthackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      currenthackerCouleur = localStorage.getItem('hackerCouleur2');
    }
    console.log(`assets/${this.epreuve}/${this.epreuve}${currenthackerCouleur}.png`);
    return `assets/${this.epreuve}/${this.epreuve}${currenthackerCouleur}.png`;

  }
}
