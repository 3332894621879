<app-entete epreuve="netflix"></app-entete>

   <div class=corps translate="no"> 
     
      <video id=videoepreuve src="/assets/netflix/videonetflix.mp4"  controls #myVideo autoplay >  </video>
      <div class=huitmots>
        <h1 class=mots> 8 MOTS EN 1 </h1>
        <h1 id=lecture (click)="playVideo()" class=liens> Lecture </h1>
        <h1 id=plus> Plus d'infos </h1>
        <h1 id=reprendre> Reprendre avec le profil de Hack Chicken </h1>
      </div>
   
  <div class=contenu>
    
    <div id= "tablejoueurs"  >
       
      <span><img id="imgAct" class="liens" src="assets/netflix/Winter-drama2.png"  border="0"  (click)="afficherIndice('gris')"/></span>
      <span><img id="imgAct" class="liens" src="assets/netflix/Marie-et-maggie.png"  border="0"  (click)="afficherIndice('rouge')"/></span>
      <span><img id="imgAct"   src="assets/netflix/Tree-of-life.png"  border="0"  /></span>
      <span><img id="imgAct" class="liens" src="assets/netflix/Love-ner-ends.png"  border="0"  (click)="afficherIndice('blanc')"/></span>
      <span><img id="imgAct"   src="assets/netflix/technologie.png"  border="0"   /></span>
      <span><img id="imgAct" class="liens" src="assets/netflix/Allons-danser2.jpg"  border="0"  (click)="afficherIndice('noir')"/></span>
      
       
       

    </div>

</div>

</div>
