<app-entete indiceMode="true" [epreuve]="this.epreuve"></app-entete>


<div id=corpsAC class=box>
   <div class=divimageindice>
      <img class="imgindice" src="{{urlindice1}}" border="0" />
   </div>
   <div class=divimageindice2>
      <img class="imgindice" src="{{urlindice2}}" border="0" />
   </div>
</div>