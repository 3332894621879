import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
 


@Component({
  selector: 'app-netflix',
  templateUrl: './netflix.component.html',
  styleUrls: ['./netflix.component.scss']
})
export class NetflixComponent implements OnInit  {
  @ViewChild('myVideo') myVideo: ElementRef; // Prior to Angular 8
   
   
  constructor(private router: Router , private realmInterface: RealmInterfaceService) {
    localStorage.setItem('epreuve', 'netflix');
    
  }
  afficherIndice(joueur : string)
  {
    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
    let url = `indicedeuxvideos/netflix`;
    if(joueur == hackerCouleur) {this.router.navigateByUrl(url);}
  }
   
  
  ngOnInit(): void {
    
      
  }
  
  

  playVideo() {
    /**
     * You are accessing a dom element directly here,
     * so you need to call "nativeElement" first.
     */
    this.myVideo.nativeElement.play();
  }
  
}
