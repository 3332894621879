import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Epreuve } from '../models/partie.model';
import { RealmInterfaceService } from '../realm-interface.service';
import { Partie } from '../models/partie.model';
import { Equipe } from '../models/partie.model';
import { LeJoueur } from '../models/partie.model';

@Component({
  selector: 'app-google',
  templateUrl: './google.component.html',
  styleUrls: ['./google.component.scss']
})
export class GoogleComponent implements OnInit {
   
  constructor(private router: Router , private realmInterface: RealmInterfaceService){
    localStorage.setItem('epreuve', 'google');
  }

  ngOnInit(): void {
     
  }

  
  afficherIndice(joueur : string)
  {
    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
    let url = `indiceuneimage/google`;
    if(joueur == hackerCouleur) {this.router.navigateByUrl(url);}
  }

  

  public onClick(){
    alert('on clicke sur chapeau noir');

  }

  
   
}
