import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Epreuve } from '../models/partie.model';
import { Partie } from '../models/partie.model';
import { Equipe } from '../models/partie.model';


@Component({
  selector: 'app-wikipedia',
  templateUrl: './wikipedia.component.html',
  styleUrls: ['./wikipedia.component.scss']
})
export class WikipediaComponent implements OnInit {
   
  ratioImage = 0.8;
  constructor(private router: Router , private realmInterface: RealmInterfaceService) {
    localStorage.setItem('epreuve', 'wikipedia');
  }
  afficherIndice(joueur : string)
  {
    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
    let url = `indiceuneimage/wikipedia`;
    if(joueur == hackerCouleur) {this.router.navigateByUrl(url);}
  }
   
  ngOnInit(): void {
    
  }
  
}
