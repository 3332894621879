<app-entete epreuve="wikipedia"></app-entete> 

<div class=corps> 
    
    <img id= "redhat" class=liens src="assets/transparent.png"  border="0" (click)="afficherIndice('rouge')" /> 
    <img id= "whitehat" class=liens src="assets/transparent.png"  border="0" (click)="afficherIndice('blanc')" />  
    <img id= "greyhat" class=liens src="assets/transparent.png"  border="0" (click)="afficherIndice('gris')" />  
    <img id= "blackhat" class=liens src="assets/transparent.png"  border="0" (click)="afficherIndice('noir')" /> 
  
</div>
