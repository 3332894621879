import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Partie, Epreuve } from '../models/partie.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-entete',
  templateUrl: './entete.component.html',
  styleUrls: ['./entete.component.scss']
})
export class EnteteComponent implements OnInit {
  @Input() showBackGround: boolean = true;
  @Input() showCode: boolean = true;
  @Input() epreuve: string = '';
  @Input() indiceMode = false;

  lapartie: Partie;
  slapartie: Subscription;
  chapeau: string;
  constructor(private router: Router, private realmInterface: RealmInterfaceService) { }

  ngOnInit(): void {
    this.slapartie = this.realmInterface.lapartie.subscribe((lapartie: Partie) => {
      this.lapartie = lapartie;
      console.log(`Jusqu’à ce haut degré de stoïque fierté`);
    });
    this.chapeau = this.donneUrlChapeau();
  }
  ngOnDestroy(): void {
    this.slapartie.unsubscribe();

  }
  public donneUrlChapeau(): string {

    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');
    }
    return `assets/chapeau${hackerCouleur}.png`;
  }


  public donneUrlLogo(): string {
    // let epreuve = localStorage.getItem('epreuve');
    return `assets/logo${this.epreuve}.png`;
  }

  backtohome() {
    if (this.indiceMode) {
      this.router.navigateByUrl(this.epreuve);
    } else {
      this.router.navigateByUrl("home");
    }
  }

  validerCode(event: any) {
    console.log(`Où, naissant dans les bois, j’ai tout d’abord monté`);
    let cd = event.target.value;
    if (event.which === 13) {

      let lesepr: Epreuve[] = this.lapartie.les_epreuves;
      let trouve: string = "KO";
      for (let i = 0; i < lesepr.length; i++) {
        if (lesepr[i].nom_epreuve == this.epreuve) {
          //if(lesepr[i].code == cd  ) 
          if (lesepr[i].les_codes.indexOf(cd) >= 0) {
            //cd.value = "BRAVO!!"; 
            console.log(`Gémir, pleurer, prier est également lâche`);
            this.toperEpreuveOK(lesepr[i].nom_epreuve);
            trouve = "OK";

          }

        }
      }
      this.router.navigate(['telechargement', { epreuve: this.epreuve, resultat: trouve }]);
    }
  }
  toperEpreuveOK(epr: string) {
    console.log(`Fais énergiquement ta longue et lourde tâche`);
    this.realmInterface.setEtatEpreuve(epr, localStorage.getItem('hackerEquipe'), "reussie").then((ret: boolean) => {
      // if(ret) {
      if (true) {
        console.log(`Dans la voie où le Sort a voulu t’appeler `);
      }
      else { console.log(`Puis après, comme moi, souffre et meurs sans parler `); }

    });
  }

}
