import { Component, OnInit , ViewChild , ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Equipe, EpreuveDeEquipe, PartieEquipe ,Joueur , LeJoueur} from '../models/partie.model';
import { RecursiveTemplateAstVisitor, templateJitUrl } from '@angular/compiler';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit {
  
  connected: boolean = false;
  lesequipes: PartieEquipe[];
  nomjoueur: string  = "INCONNU";

  @ViewChild('joueurSaisi') joueurSaisi;
  @ViewChild('mdpSaisi') mdpSaisi;



   
   
  joueurRemplace : Joueur = {"couleur":"" ,"mot_de_passe":"","nom_joueur":"","statut":""};

  afficherLogin : boolean = false;
  afficherContinuer : boolean = false;
  afficherRemplacer : boolean = false;
  afficherSalle : boolean = false;
  afficherJoueur : boolean = false;

  nomsEquipes: string[];
  nomsCouleurs: string[];

  retourMdp : string = "KO";
  lejoueur : LeJoueur = {"nom_joueur":"" ,"partie":"","equipe":"", "couleur":"", "mot_de_passe":"", "statut":""};
  lesEquipes :PartieEquipe [] = [];
  slesEquipes: Subscription;
  sconnected: Subscription;
  // pour le chronometre
  ms=0;s=0;mn=0;h=0;
  hd = 0; md = 0; sd = 0 ; DebEnSec = 0;

  constructor(private realmInterface: RealmInterfaceService,private router: Router ) { }

  ngOnInit(): void {
    
    const boutonLogin  = <HTMLInputElement> document.getElementById("btLogin");
    const boutonJoueur  = <HTMLInputElement> document.getElementById("btjoueur");
    const boutonJoueur2  = <HTMLInputElement> document.getElementById("btjoueur2");
     
    this.sconnected = this.realmInterface.connected.subscribe(connected => {
       
      this.connected = connected;
      // Alors la ligne suivante je la laisse là parceque tu l'avais mis dans cette classe, 
      // mais je pense que ça aurait sans doute plus sa place dans le service.
      this.realmInterface.donnePartieEquipes("Le dating 2");
    });
    
    this.slesEquipes = this.realmInterface.lesEquipes.subscribe(x => {
      if (x.length === 0) return;  // cas particulier du début où le tableau est vide faute d'avoir reçu quelque chose.
      this.lesequipes = x;
       
      if (this.lesequipes[0].jeton) {// existe partie.JETON : on dispose des donnees de la partie
        console.log(`type login  : ${this.lesequipes[0].typelogin}  `);
        if(this.lesequipes[0].typelogin=="salle" ){ this.afficherSalle = true; this.afficherJoueur = false}
        else{this.afficherJoueur = true ; this.afficherSalle = false;};
        this.recupLibelles(); // on alimente nomsCouleurs et nomsEquipes à partir de "lesEquipes"
        if(this.lesequipes[0].etat_partie=="lancee" )
            {
               this.startChrono();
            };
        if (localStorage.getItem("jeton")!==null) {//   il existe deja une VL jeton   
          if (localStorage.getItem("jeton") ==this.lesequipes[0].jeton) {
            if(localStorage.getItem('etatJoueur') == "INCONNU"){ this.afficherLogin = true; };//  
            if(localStorage.getItem('etatJoueur') == "CONNU" || localStorage.getItem('etatJoueur') == "EN_JEU"){ 
              this.afficherLogin = false;
              this.nomjoueur = localStorage.getItem('hackerNom');
              if(this.joueurAreinitialiser(this.nomjoueur)){  
                console.log(`joueur a reinitialiser `);
                this.passeJoueurAInnactif(this.nomjoueur , localStorage.getItem('hackerEquipe'))
                this.initPartie();
              }
              else{
              this.afficherContinuer = true;
              let tj = this.donneJoueursARemplacer();
                  if (tj.length>0){// il existe au moins un joueur a remplacer
                    this.joueurRemplace = tj[0] ;
                    this.afficherRemplacer = true;
                  }
                 else{// le jouer est connu et personne a remplacer , on continue avec lui
                       this.backtohome();
                 }
                }
            }
          }
        else{// les deux jetons ne sont pas egaux
          this.initPartie();
        }
      }
      else{//on ne dispose pas d'une VL jeton ou le joueur est à reinitialiser 
      this.initPartie();
      }
      }
      else{//on ne dispose pas des donnees de la partie
          ; // on ne fait rien tant qu'on a pas pu recuperer infos sur la partie en base
      }
     
    
   
      // fin else
    })  // fin lesequipesShare.subscribe
 
    }
    ngOnDestroy(): void {
      this.sconnected.unsubscribe();
      this.slesEquipes.unsubscribe();
  
    }
    /*On alimente les noms des equipes et le nom des couleurs 
    */
    recupLibelles(){
       
       this.nomsEquipes =  this.lesequipes.map((equipe)=>{
        return    equipe.nom_equipe ;
      });
      this.nomsCouleurs   =  this.lesequipes[0].les_joueurs.map((joueur)=>{
        return    joueur.couleur ;
      });  

    }
  backtohome()  { this.router.navigateByUrl("home");}

  public login() {
    let url = `login`;
     
    this.router.navigateByUrl(url);
  }

  /* diverses methodes pour le chronometre */
public startChrono(){
   
      this.hd = parseInt(this.lesequipes[0].heureDebut );  
      this.md = parseInt(this.lesequipes[0].minDebut );  
      this.sd = parseInt(this.lesequipes[0].secDebut );  
      this.DebEnSec =( this.hd * 3600) + (this.md * 60) + this.sd;
  setInterval(()=>{
    this.update_chrono(); },1000); 
 
    }
   

   update_chrono(){
     
    let ladate=new Date();
     let hd = ladate.getHours() ; 
    let md = ladate.getMinutes() ;
    let sd = ladate.getSeconds() ;
    let NowEnSec =( hd * 3600) + (md * 60) + sd;

    let tpsEcoule = NowEnSec - this.DebEnSec;
   this.h =  Math.floor(tpsEcoule/3600);
   tpsEcoule = tpsEcoule - (this.h * 3600);
   this.mn =  Math.floor(tpsEcoule/60);
   this.s = tpsEcoule - (this.mn * 60);


    
     return Promise.resolve(true);

}
initPartie(){
  // suppression de toutes le VL
  
  this.nettoieVariablesLocales().then((ret: boolean) => {
    if (true) {
      console.log(`je ne lui ai pas sauve la vie  `);
      // on prend le jeton de la partie
  localStorage.setItem('jeton', this.lesequipes[0].jeton);
  // le joueur existe (je clicke donc je suis) mais est inconnu
  localStorage.setItem('etatJoueur', "INCONNU"); 
       
    }
    else { console.log(`j'ai empêche qu'on le tue `); }

  }); 
  
  
}
VLeffacees() : boolean{
  if(localStorage.getItem('hackerCouleur')!==null){return false;};
  if(localStorage.getItem('hackerNom')!==null){return false;};
  if(localStorage.getItem('hackerEquipe')!==null){return false;};
  if(localStorage.getItem('hackerPartie')!==null){return false;};
  if(localStorage.getItem('etatJoueur')!==null){return false;};
  if(localStorage.getItem('hackerCouleur2')!==null){return false;};
  if(localStorage.getItem('hackerNom2')!==null){return false;};
  if(localStorage.getItem('hackerEquipe2')!==null){return false;};
  if(localStorage.getItem('hackerPartie2')!==null){return false;};
  if(localStorage.getItem('etatJoueur2')!==null){return false;};
  /*
  localStorage.setItem('hackerNom', this.lejoueur.nom_joueur);
  localStorage.setItem('hackerEquipe', this.lejoueur.equipe);
  localStorage.setItem('hackerPartie', this.lejoueur.partie);
  localStorage.setItem('etatJoueur', "CONNU"); 
  */
 return true; 
}
async nettoieVariablesLocales(): Promise<boolean> {
   
  localStorage.clear();
  while(!this.VLeffacees()){localStorage.clear();};
   
      return Promise.resolve( true);
       
       
     
  }
  
 

/*
on regarde si on etait deja en train de remplacer quelqu'un :
    Un hackerNom2 existe et on est sur le joueur principal
    alors on renvoie ce joueur.
on regarde en base les joueurs à remplacer dans cette equipe et les restitue
*/
donneJoueursARemplacer() :   Joueur[]{
 
  let  tj: Joueur[] =  [];
   let  jr : Joueur = {"couleur":"" ,"mot_de_passe":"","nom_joueur":"","statut":""};
   let reprise  : boolean = ((localStorage.getItem('hackerNom2')!==null)&&(this.realmInterface.getJoueurEnCours()==0));
    
   if(reprise){
     
    jr.couleur = localStorage.getItem('hackerCouleur2');
    jr.nom_joueur = localStorage.getItem('hackerNom2');
    jr.mot_de_passe = "" ;
    jr.statut = "remplace";
    tj.push(jr);
     
    
   }
   else{
  const monequipe = this.lesequipes.find(equ => equ.nom_equipe==localStorage.getItem('hackerEquipe'));
     tj = monequipe.les_joueurs.filter( joueur=>joueur.statut=="hors_jeu");
  }
  return tj;
}

joueurAreinitialiser(nom : string) : boolean{
  const equipe = this.lesequipes.find(equ => equ.nom_equipe==localStorage.getItem('hackerEquipe'));
  const    j = equipe.les_joueurs.find( joueur=>joueur.nom_joueur==nom);
  if(j.statut == "reinitialiser"){   
    return true;
  } else {return false;}

}
continuer(){
  this.backtohome();
}

passeJoueurAInnactif(nomjoueur : string , equipe : string){
  this.realmInterface.setJoueurEnCours(0); // 0 , joueur principal , 1 joueur remplace
  const lejoueur   = {"nom_joueur": nomjoueur  , "equipe": equipe};
  this.realmInterface.setStatutJoueur(lejoueur , "innactif").then((ret : boolean)  => {
    // if(ret) {
     if(true) {
        
       ;}
     else {console.log(`probleme dans passeJoueurAInnactif: ${ret}  `);}
     
     });

}

remplacer(){
      localStorage.setItem('hackerCouleur2', this.joueurRemplace.couleur);
      localStorage.setItem('hackerNom2', this.joueurRemplace.nom_joueur);
      localStorage.setItem('etatJoueur2', "EN_JEU"); 

      this.realmInterface.setJoueurEnCours(1); // 0 , joueur principal , 1 joueur remplace
      const lejoueur   = {"nom_joueur": this.joueurRemplace.nom_joueur  , "equipe":localStorage.getItem('hackerEquipe')};
      this.realmInterface.setStatutJoueur(lejoueur , "remplace").then((ret : boolean)  => {
        // if(ret) {
         if(true) {
            
           this.backtohome();}
         else {console.log(`probleme dans set statut  à remplace: ${ret}  `);}
         
         });
  ;
}
verifLogin(doc){
   
 
  let username = this.joueurSaisi.nativeElement.value;
 
  let password = this.mdpSaisi.nativeElement.value;
   
 
        
          this.realmInterface.valideMdP(username ,password).then((ret) => {
           
         if(ret!="KO") {
            
           this.retourMdp = ret; // le nom de l 'quipe est retournee par validMdP
           this.lejoueur.equipe = ret;
           this.lejoueur.nom_joueur = username;
           if( this.dejaEnJeu(username))
       {
        alert(`le joueur ${username}  est deja entrain de jouer , indiquez un autre joueur`);
        this.retourMdp = "KO";
       }

           }
         else {
           alert(`Le nom du joueur ou son mot de passe est erroné`); 
           };
          
         // if (  password == this.lejoueur.mot_de_passe) {
           if (  this.retourMdp != "KO") {
            this.initLeJoueur(this.lejoueur.nom_joueur);
             
              
             this.initJoueur(); 
             this.realmInterface.setStatutJoueur(this.lejoueur , "actif").then((ret : boolean)  => {
              // if(ret) {
               if(true) {
                
                 this.backtohome();}
               else {console.log(`probleme dans set statut : ${ret}  `);}
               
               });
         }
         
        });
         
         
          


   // }
  }

  verifLoginSalle(doc){
 
    let sel_salle = <HTMLSelectElement> document.getElementById("salleSaisi");
    console.log(`n  : ${sel_salle.options[sel_salle.selectedIndex].value}  `);
    let salle : string = sel_salle.options[sel_salle.selectedIndex].value;
    
    let sel_couleur = <HTMLSelectElement> document.getElementById("couleurSaisi");
    console.log(`n  : ${sel_couleur.options[sel_couleur.selectedIndex].value}  `);
    let couleur : string = sel_couleur.options[sel_couleur.selectedIndex].value;
    
    let username = salle.concat(couleur.toString().charAt(0));
    console.log(`username  : ${username}  `);
    let password = couleur;
    
          
            this.realmInterface.valideMdP(username ,password).then((ret) => {
             
           if(ret!="KO") {
              
             this.retourMdp = ret; // le nom de l 'quipe est retournee par validMdP
             this.lejoueur.equipe = ret;
             this.lejoueur.nom_joueur = username;
             if( this.dejaEnJeu(username))
         {
          alert(`le joueur ${username}  est deja entrain de jouer , indiquez un autre joueur`);
          this.retourMdp = "KO";
         }
  
             }
           else {
             alert(`Le nom du joueur ou son mot de passe est erroné`); 
             };
            
           // if (  password == this.lejoueur.mot_de_passe) {
             if (  this.retourMdp != "KO") {
              this.initLeJoueur(this.lejoueur.nom_joueur);
               
                
               this.initJoueur(); 
               this.realmInterface.setStatutJoueur(this.lejoueur , "actif").then((ret : boolean)  => {
                // if(ret) {
                 if(true) {
                  
                   this.backtohome();}
                 else {console.log(`probleme dans set statut : ${ret}  `);}
                 
                 });
           }
           
          });
           
           
            
  
  
     // }
    }

    

  initJoueur() {
    localStorage.setItem('hackerCouleur', this.lejoueur.couleur);
    localStorage.setItem('hackerNom', this.lejoueur.nom_joueur);
    localStorage.setItem('hackerEquipe', this.lejoueur.equipe);
    localStorage.setItem('hackerPartie', this.lejoueur.partie);
  //  localStorage.setItem('hackerMdP', this.lejoueur.mot_de_passe);
    localStorage.setItem('etatJoueur', "CONNU"); 
  }

initLeJoueur(nom : string){
const equipe  = this.lesequipes.find(equ => equ.nom_equipe==this.lejoueur.equipe);
    
  const joueur = equipe.les_joueurs.find(jou => jou.nom_joueur == nom);
   
        this.lejoueur.partie = equipe.nom_partie;
        this.lejoueur.couleur = joueur.couleur;
}
/* renvoie true si le joueur est deja en train de jouer sur une autre poste
     
*/
dejaEnJeu(nom : string) : boolean{
  

  const equipe  = this.lesequipes.find(equ => equ.nom_equipe==this.lejoueur.equipe);
      if(equipe){
      const joueur = equipe.les_joueurs.find(jou => jou.nom_joueur == nom);
      if(joueur.statut == "actif" || joueur.statut == "remplace"){
        return true;
      }else{ return false; }
    } else {return false;}
  
}
}
 