<!--
<div id=barent> 
   <div class=boxentete >
    
        
    <span>   <img id="logojoueur" src="{{this.donneUrlChapeau()}}"  border="0"  /> </span>
    <span>   <img id="logoepreuve" src="{{this.donneUrlLogo()}}"  border="0"  /> </span>
    <span id="spanCode" style= "color: #4de9e8; font-weight: bold;">
                       Code :   <input type="text" #code   name = "Inputcode"     maxlength = "10" style= "border-radius: 15px;"
                       (keyup)="validerCode( $event)" > 
              </span>
    <span>   <img id="logohome" src="assets/Profil.png"  border="0"  (click)="backtohome()"/> </span>
   

   </div>

</div>

 -->  
 <app-entete epreuve="marmiton"></app-entete> 

   <div class=corps   > 
    <!--
      <video id=videoepreuve src="/assets/marmiton/videomarmiton.mp4" width=220  height=140 controls autoplay >  </video>
      
      <img id= whitehat class=liens  src="assets/transparent.png"  border="0" (click)="afficherIndice('blanc')" />  
        <img id= blackhat class=liens  src="assets/transparent.png"  border="0" (click)="afficherIndice('noir')" />  
    <img id= greyhat class=liens  src="assets/transparent.png"  border="0" (click)="afficherIndice('gris')" /> 
    <img id= redhat class=liens  src="assets/transparent.png"  border="0" (click)="afficherIndice('rouge')" /> 
   --> 
   <div   class= "tableCorps"  > 
      <div class= "vide"   >   <p>   </p>  </div>  
      <div class= "plein"   > 
         <div id="haut" >
         <div   class= "tableImagesRondes"  >
            <div   class= "table2ImagesRondes"  >
               <div class="uneimageRonde">
                 <div class="imageRonde"  >   <img   id="ronde" src="/assets/marmiton/Recette-du-jour3.png"  border="0"   /> </div>
                 <div class= "txtNormal" > Recette du jour</div>
               </div>  
               <div class="uneimageRonde">
                  <div class="imageRonde" >   <img  id="ronde" src="/assets/marmiton/Menu-de-la-semaine3.png"  border="0"   /> </div>
                  <div class= "txtNormal"  > Menus de la semaine</div>
                </div> 
            </div> <!--fin table 2 images rondes --> 
            <div   class= "table2ImagesRondes"  >
               <div class="uneimageRonde">
                 <div class="imageRonde" >   <img   id="ronde" src="/assets/marmiton/Coup-de-coeur3.png"  border="0"   /> </div>
                 <div class= "txtNormal"  > Coups de coeur</div>
               </div>  
               <div class="uneimageRonde">
                  <div class="imageRonde" >   <img  id="ronde" src="/assets/marmiton/Mieux-manger3.png"  border="0"   /> </div>
                  <div class= "txtNormal" > Mieux manger</div>
                </div> 
            </div> <!--fin table 2 images rondes -->   
         </div> <!--fin table images rondes --> 
         <video id=videoepreuve src="/assets/marmiton/videomarmiton.mp4" width=400  height=270 controls autoplay >  </video>
      </div> <!--fin haut --> 
         
      
      <div id="lesRecettes">
         <div id="TitreRecettes"> Les recettes mixées </div>
         <div class="LesImagesRecettes"> 
            <div class="LesImagesRecettesL1">
               <div class="divN1" id="recetteBlanche">
                  <div class="divImg">
                    <img  class="image"   src= "/assets/marmiton/White hatB.jpg" border="0" (click)="afficherIndice('blanc')"/>  
                  </div>
                  <div class="divTxt"> 
                  <div>    <p class="ptexte">Special grand chef : <br> Poulet-carotte</p> </div>
                  </div>
              </div>   
              <div class="divN1" id="recetteNoire">
               <div class="divImg">
                 <img  class="image"   src= "/assets/marmiton/Black hat.jpg" border="0" (click)="afficherIndice('noir')" />  
               </div>
               <div class="divTxt"> 
                   <p class="ptexte">Le chicken burger   <br> cornichons</p> 
               </div>
           </div>     
            
            <div class="divN1" id="recetteGrise">
               <div class="divImg">
                   <img  class="image"   src= "/assets/marmiton/Grey hat.jpg" border="0" (click)="afficherIndice('gris')"/>  
               </div>
               <div class="divTxt"> 
                  <p class="ptexte">  Bien cuire  <br> son rôti</p> 
               </div>
            </div>
            </div>  <!--fin LesImagesRecettesL1 --> 
            <div class="LesImagesRecettesL2">
               
      
               <div class="divN1" id="complement1">
                  <div class="divImg">
                      <img  class="image"   src= "/assets/marmiton/Gateaux.jpeg" border="0"/>  
                  </div>
                  <div class="divTxt"> 
                     <p class="ptexte">Les nuggets <br> glacés</p> 
                  </div>
               </div> 

               <div class="divN1" id="recetteRouge">
                  <div class="divImg">
                      <img  class="image"   src= "/assets/marmiton/Red hat.jpg" border="0" (click)="afficherIndice('rouge')"/>  
                  </div>
                  <div class="divTxt"> 
                     <p class="ptexte">Cookies   <br> aux oeufs frais</p> 
                  </div>
               </div>

               <div class="divN1" id="complement2">
                  <div class="divImg">
                      <img  class="image"   src= "/assets/marmiton/Gateaux 2.jpeg" border="0"/>  
                  </div>
                  <div class="divTxt"> 
                     <p class="ptexte">Les pâtisseries <br> Poulette</p> 
                  </div>
               </div> 
            </div>  <!--fin LesImagesRecettesL2 --> 
            

         

            
         </div> <!--fin LesImagesRecettes --> 

      </div> <!--fin les resettes -->
      
      </div>  <!--fin plein -->
           
      <div   class= "vide2"   >   <p>   </p>  </div>
   </div> <!--fin table corps -->
   </div>  <!--fin corps -->

   