<app-entete epreuve="youtube"></app-entete> 
 
    
     
 
    <div class=corps> 
      <div id=tout>
        <div  id=gauche>
            
            <div id=gaucheH>
                <div id=video>
     
                    <video id=videoepreuve src="/assets/youtube/videoyoutube.mp4"   controls autoplay >  </video>
    
                </div>
            </div> <!-- fin gaucheH --> 
            <div id=gaucheB>
                <div id=gaucheB1 class=txtNormal>
                    
                      <p style="font-size: 8pt;"> #hack#information</p> 
                        <p style="font-size: 12pt;font-weight:bold;"> HackChicken - Les méandres de la plateforme</p> 
                        <p style="font-size: 8pt; "> 0 vue - 20 novembre 2020       <span style="font-size: 8pt; margin-right: 50px; float:right;"> ENREGISTRER   </span>  </p> 
                 
                </div> <!-- fin gaucheB1 --> 
                <div id=gaucheB2>
                    <hr>
                    <div class="div-categoryContainer">
                        <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 40px; height: 40px; " >
                        <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                            <p class="txtNormal"> <span style="font-size: 10pt;font-weight:bold;">  Hack Chicken     </span>   <br>
                                <span style="font-size: 8pt;"> 1 abonné  </span> <span style="font-size: 8pt;  margin-right: 50px; float:right;background-color: grey;padding: 0.5em;"> ABONNE   </span> 
                            </p>  
                         </div>
                     </div>
                 
                </div> <!-- fin gaucheB2 --> 
            </div> <!-- fin gaucheB --> 
        </div> <!-- fin gauche -->
        <div id=droite>
            <table id="tablechapeaux" class="tableau" border="0" style=" margin-left : 10px; border-left: 10px; border-spacing: 10px;" >
                <tr>
                    <th class="txtNormal" style="text-align: left;"> A suivre </th>   
                </tr>
                 
                <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                  <td>  
                    <div class="div-categoryContainer">
                        <img src="assets/youtube/HC vidéo.png"  class="picture-left" style="width: 100px; height: auto; " >
                        <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                            <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken     </span>   <br>
                                <span style="font-size: 6pt;"> Hack Chicken   </span>
                                <br>
                                <span style="font-size: 6pt;"> 0 vue - il y a 1 jour   </span>
                            </p>  
                         </div>
                     </div>
                  </td>  
                </tr> 
                <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                    <td> 
                        <hr> 
                      <div class="div-categoryContainer">
                          
                          <img src="assets/youtube/La-danse-des-canards.jpeg"  class="picture-left" style="width: 100px; height: auto; " >
                          <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                              <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Live : La danse des canards     </span>   <br>
                                  <span style="font-size: 6pt;"> Hack Chicken   </span>
                                  <br>
                                  <span style="font-size: 6pt;"> 0 vue - il y a 1 jour   </span>
                              </p>  
                           </div>
                       </div>
                    </td>  
                  </tr> 
                  <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                    <td> 
                        <div class="div-categoryContainer">
                          <img src="assets/youtube/Nuggets.jpeg"  class="picture-left" style="width: 100px; height: auto;cursor: crosshair; " (click)="afficherIndice('rouge')">
                          <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                              <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Que se cache t'il dans les nuggets     </span>   <br>
                                  <span style="font-size: 6pt;"> Hack Chicken   </span>
                                  <br>
                                  <span style="font-size: 6pt;"> 0 vue - il y a 1 jour   </span>
                              </p>  
                           </div>
                       </div>
                    </td>  
                  </tr>
                  <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                    <td> 
                        <div class="div-categoryContainer">
                          <img src="assets/youtube/Industrie-du-poulet.jpeg"  class="picture-left" style="width: 100px; height: auto; cursor: crosshair;" (click)="afficherIndice('blanc')">
                          <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                              <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  L'industrie du poulet     </span>   <br>
                                  <span style="font-size: 6pt;"> Hack Chicken   </span>
                                  <br>
                                  <span style="font-size: 6pt;"> 0 vue - il y a 1 jour   </span>
                              </p>  
                           </div>
                       </div>
                    </td>  
                  </tr> 
                  <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                    <td> 
                        <div class="div-categoryContainer">
                          <img src="assets/youtube/Des-graines-et-du-son.jpeg"  class="picture-left" style="width: 100px; height: auto; " >
                          <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                              <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Nouveau single : Des graines et du son     </span>   <br>
                                  <span style="font-size: 6pt;"> Hack Chicken   </span>
                                  <br>
                                  <span style="font-size: 6pt;"> 0 vue - il y a 1 jour   </span>
                              </p>  
                           </div>
                       </div>
                    </td>  
                  </tr> 
                  <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                    <td> 
                        <div class="div-categoryContainer">
                          <img src="assets/youtube/Chicken-Zombie.jpeg"  class="picture-left" style="width: 100px; height: auto;cursor: crosshair; " (click)="afficherIndice('noir')">
                          <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                              <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Chicken Zombie     </span>   <br>
                                  <span style="font-size: 6pt;"> Hack Chicken   </span>
                                  <br>
                                  <span style="font-size: 6pt;"> 0 vue - il y a 1 jour   </span>
                              </p>  
                           </div>
                       </div>
                    </td>  
                  </tr>
                  <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                    <td> 
                        <div class="div-categoryContainer">
                          <img src="assets/youtube/Chair-de-poule.jpeg"  class="picture-left" style="width: 100px; height: auto; " >
                          <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                              <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Zen Sound : Chair de poule    </span>   <br>
                                  <span style="font-size: 6pt;"> Hack Chicken   </span>
                                  <br>
                                  <span style="font-size: 6pt;"> 0 vue - il y a 1 jour   </span>
                              </p>  
                           </div>
                       </div>
                    </td>  
                  </tr>
                  <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                    <td> 
                        <div class="div-categoryContainer">
                          <img src="assets/youtube/Cute-hens-and-dogs.jpeg"  class="picture-left" style="width: 100px; height: auto;cursor: crosshair; " (click)="afficherIndice('gris')" >
                          <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                              <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Cute hens & dogs    </span>   <br>
                                  <span style="font-size: 6pt;"> Hack Chicken   </span>
                                  <br>
                                  <span style="font-size: 6pt;"> 0 vue - il y a 1 jour   </span>
                              </p>  
                           </div>
                       </div>
                    </td>  
                  </tr>    
            </table>   
        </div> <!-- fin droite -->
    </div>  <!-- fin tout -->
<!--
            <div id=video>
     
                <video id=videoepreuve src="/assets/youtube/videoyoutube.mp4"   controls autoplay >  </video>

            </div>
            <div id=joueurs>
                <div class=box>
                <img id= xredhat class=imgjoueur   src="assets/transparent.png"  border="0"   /> 
                <img id= xredhat class=imgjoueur  src="assets/transparent.png"  border="0" (click)="afficherIndice('rouge')" /> 
                <img id= xwhitehat class=imgjoueur  src="assets/transparent.png"  border="0" (click)="afficherIndice('blanc')" /> 
                <img id= xrien class=imgjoueur  src="assets/transparent.png"  border="0"   /> 
                <img id= xblackhat class=imgjoueur  src="assets/transparent.png"  border="0" (click)="afficherIndice('noir')" /> 
                <img id= xrien class=imgjoueur  src="assets/transparent.png"  border="0"   />  
                <img id= xgreyhat class=imgjoueur  src="assets/transparent.png"  border="0" (click)="afficherIndice('gris')" /> 
            </div>
-->         
   </div>
 
