import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { LeJoueur } from '../models/partie.model';
import { Equipe, EpreuveDeEquipe, PartieEquipe } from '../models/partie.model';
import { Subscription } from 'rxjs';

export enum NomEpreuve {
  UN = 'google',
  DEUX = 'babbel',
  TROIS = 'netflix',
  QUATRE = 'voici',
  CINQ = 'spotify',
  SIX = 'wikipedia',
  SEPT = 'youtube',
  HUIT = 'marmiton',
  NEUF = 'twitch',
  DIX = 'allocine'
};

export enum HackerType {
  BLACK = 'noir',
  RED = 'rouge',
  WHITE = 'blanc',
  GREY = 'gris'
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  connected: boolean = false;
  //lejoueur: LeJoueur = { "nom_joueur": "", "partie": "", "equipe": "", "couleur": "", "mot_de_passe": "", "statut": "" };
  lesequipes: PartieEquipe[];
  slesEquipes: Subscription;
  sconnected: Subscription;
  lesepreuvesdeequipe: EpreuveDeEquipe[];
  eNomEpreuve = NomEpreuve;
  //eHackerType = HackerType;
  eNomEquipe = "?";
  private intervalChrono;

  tabEpreuves = [
    { "nom": "marmiton", "statut": "" },
    { "nom": "twitch", "statut": "" },
    { "nom": "wikipedia", "statut": "" },
    { "nom": "netflix", "statut": "" },
    { "nom": "spotify", "statut": "" },
    { "nom": "allocine", "statut": "" },
    { "nom": "babbel", "statut": "" },
    { "nom": "youtube", "statut": "" },
    { "nom": "google", "statut": "" },
    { "nom": "voici", "statut": "" },
  ];
  //tabEpreuves : string[] = ["" , "" ,"" ,"" , "" ,"" ,"", "" ,""  ,""  ];
  tabJoueurs: string[] = ["", "", "", ""];
  tabJoueurs2: string[][] = [["", ""], ["", ""], ["", ""], ["", ""]];
  tabJoueursCouleur: string[] = ["green", "green", "green", "green"];

  ratioImage = 0.8;
  // pour le chronometre
  ms = 0; s = 0; mn = 0; h = 0;
  hd = 0; md = 0; sd = 0; DebEnSec = 0;

  constructor(private realmInterface: RealmInterfaceService, private router: Router) {
    localStorage.setItem('epreuve', 'aucune');
  }

  ngOnInit(): void {
    console.log(`malgré ce grand nom d’Hommes`);

    this.sconnected = this.realmInterface.connected.subscribe(connected => {
      console.log(`Hélas ! ai-je pensé  `);
      this.connected = connected;
      // Alors la ligne suivante je la laisse là parceque tu l'avais mis dans cette classe, 
      // mais je pense que ça aurait sans doute plus sa place dans le service.
      this.realmInterface.donnePartieEquipes("Le dating 2");
      //
      if (this.connected) {

        this.slesEquipes = this.realmInterface.lesEquipes.subscribe(x => {
          if (x.length === 0) return;  // cas particulier du début où le tableau est vide faute d'avoir reçu quelque chose.
          this.lesequipes = x;
          if (this.lesequipes[0].jeton) {// existe partie.JETON : on dispose des donnees de la partie
            if (this.lesequipes[0].etat_partie == "lancee") {
              this.startChrono();
            };
            if (localStorage.getItem("jeton") !== null) {// existe t il deja une VL jeton
              let etatjoueur = localStorage.getItem('etatJoueur');
              console.log(`home getJoueurEnCours() : ${this.realmInterface.getJoueurEnCours()}  `);
              if (this.realmInterface.getJoueurEnCours() != 0) { etatjoueur = localStorage.getItem('etatJoueur2'); };
              if (localStorage.getItem("jeton") == this.lesequipes[0].jeton) {
                if (localStorage.getItem('etatJoueur') == "INCONNU") { // boutonLogin.style.visibility = "visible";
                  ;
                };//  
                if (localStorage.getItem('etatJoueur') == "CONNU") {
                  // boutonLogin.style.visibility = "hidden";
                  this.initJoueur();
                  // to do : rendre visible bouton logout
                  console.log(`Que j’ai honte de nous`);
                  this.afficherNomJoueurs();
                  if (this.lesequipes[0].etat_partie == "lancee") {
                    if (this.realmInterface.getJoueurEnCours() != 0) {
                      localStorage.setItem('etatJoueur2', "EN_JEU");
                    }
                    else { localStorage.setItem('etatJoueur', "EN_JEU"); };
                  };
                }; // fin == "CONNU"
                if (localStorage.getItem('etatJoueur') == "EN_JEU") {
                  console.log(`débiles que nous sommes `);
                  this.afficherNomJoueurs();
                  this.afficherStatutEpreuves();
                };  // fin == "EN_JEU"
              }
              else {// les deux jetons ne sont pas egaux
                this.initPartie();
              }
            }
            else {//on ne dispose pas d'une VL jeton
              this.initPartie();
            }
          }
          else {//on ne dispose pas des donnees de la partie
            ; // on ne fait rien tant qu'on a pas pu recuperer infos sur la partie en base
          }
          // fin else
        })  // fin lesequipesShare.subscribe
      } // fin if connected
    });
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalChrono);
    console.log(`Comment on doit quitter la vie et tous ses maux`);
    this.sconnected.unsubscribe();
    this.slesEquipes.unsubscribe();
  }
  initPartie() {
    // suppression de toutes le VL
    localStorage.removeItem('hackerCouleur');
    localStorage.removeItem('hackerNom');
    localStorage.removeItem('hackerEquipe');
    localStorage.removeItem('hackerPartie');
    //localStorage.removeItem('hackerMdP');
    // on prend le jeton de la partie
    localStorage.setItem('jeton', this.lesequipes[0].jeton);
    // le joueur existe (je clicke donc je suis) mais est inconnu
    localStorage.setItem('etatJoueur', "INCONNU");
  }




  public resizeArea(cx: number, cy: number, radius: number): string {
    return `${cx * this.ratioImage},${cy * this.ratioImage},${radius * this.ratioImage}`;
  }
  initJoueur() {

    this.eNomEquipe = localStorage.getItem('hackerEquipe');
  }

  public onClick(hackerCouleur: HackerType) {

    let nbModif = localStorage.getItem('nbModifHacker');
    if (nbModif == null) {
      //localStorage.setItem('nbModifHacker', '0');
      localStorage.removeItem('hackerCouleur');
    } else {
      nbModif = nbModif + 1;
      //localStorage.setItem('nbModifHacker', nbModif);
      localStorage.removeItem('hackerCouleur');
    }
    let currenthackerCouleur = localStorage.getItem('hackerCouleur');
    if (currenthackerCouleur != null && currenthackerCouleur != hackerCouleur) {
      /* en test j'autorise de changer de joueur 
      alert('Pas le droit de changer');
      */
      localStorage.setItem('hackerCouleur', hackerCouleur);
      localStorage.setItem('progression', '0');
      let progression = 0;
    } else {
      localStorage.setItem('hackerCouleur', hackerCouleur);
      localStorage.setItem('progression', '0');
      let progression = 0;
      /*  let url = `${hackerCouleur}-${progression}`;
       this.router.navigateByUrl(url); */
    }

  }
  public onClickEpreuve(epreuve: NomEpreuve) {
     
    console.log(`onClickEpreuve : ${epreuve}`);
    if (localStorage.getItem('etatJoueur') == "EN_JEU") {
      let url = `${epreuve}`;
     

      this.router.navigateByUrl(url);
    }

  }


  public quiter() {
    let hackerName = localStorage.getItem('hackerNom');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace

      hackerName = localStorage.getItem('hackerNom2');
    }

    const equipe = this.lesequipes.find(equ => equ.nom_equipe == localStorage.getItem('hackerEquipe'));

    const joueur = equipe.les_joueurs.find(jou => jou.nom_joueur == hackerName);
    const lejoueur = { "nom_joueur": joueur.nom_joueur, "equipe": equipe.nom_equipe };

    this.realmInterface.setStatutJoueur(lejoueur, "hors_jeu").then((ret: boolean) => {
      if (true) {
        console.log(`C’est vous qui le savez, sublimes animaux  `);
        //  todo : fermer la fenetre
        //   supprimer les VL
        this.initPartie();
      }
      else { console.log(`A voir ce que l’on fut sur terre et ce qu’on laisse `); }

    });
  }
  public score() {
    let url = `score`;
    

    this.router.navigateByUrl(url);


  }
  /* affiche sous chaque "chapeau " le nom du joueur
     affiche le nom de l'equipe
  */
  afficherNomJoueurs() {
    console.log(`  Seul le silence est grand ; tout le reste est faiblesse`);

    for (let i = 0; i < this.lesequipes.length; i++) {

      let equipe = this.lesequipes[i];

      if (equipe.nom_equipe == localStorage.getItem('hackerEquipe')) {
        this.eNomEquipe = localStorage.getItem('hackerEquipe');

        for (let k = 0; k < this.tabJoueurs2.length; k++) {

          while (this.tabJoueurs2[k].length > 1) {
            this.tabJoueurs2[k].pop();
          };

        };

        for (let j = 0; j < equipe.les_joueurs.length; j++) {
          let statut = false;
          if (equipe.les_joueurs[j].statut == "actif" || equipe.les_joueurs[j].statut == "remplace") { statut = true };


          if (equipe.les_joueurs[j].couleur == "noir" && statut) { this.tabJoueurs2[0].push(equipe.les_joueurs[j].nom_joueur); };
          if (equipe.les_joueurs[j].couleur == "gris" && statut) { this.tabJoueurs2[1].push(equipe.les_joueurs[j].nom_joueur); };
          if (equipe.les_joueurs[j].couleur == "blanc" && statut) { this.tabJoueurs2[2].push(equipe.les_joueurs[j].nom_joueur); };
          if (equipe.les_joueurs[j].couleur == "rouge" && statut) { this.tabJoueurs2[3].push(equipe.les_joueurs[j].nom_joueur); };
          /*
           if (equipe.les_joueurs[j].couleur == "noir" && statut ) { this.tabJoueurs[0] = equipe.les_joueurs[j].nom_joueur; };
           if (equipe.les_joueurs[j].couleur == "gris" && statut ) { this.tabJoueurs[1] = equipe.les_joueurs[j].nom_joueur; };
          if (equipe.les_joueurs[j].couleur == "blanc" && statut  ) { this.tabJoueurs[2] = equipe.les_joueurs[j].nom_joueur; };
          if (equipe.les_joueurs[j].couleur == "rouge" && statut) { this.tabJoueurs[3] = equipe.les_joueurs[j].nom_joueur; };
          */

          if (equipe.les_joueurs[j].couleur == "noir") { this.tabJoueursCouleur[0] = this.getColorJoueur(equipe.les_joueurs[j].statut, equipe.les_joueurs[j].nom_joueur); };
          if (equipe.les_joueurs[j].couleur == "gris") { this.tabJoueursCouleur[1] = this.getColorJoueur(equipe.les_joueurs[j].statut, equipe.les_joueurs[j].nom_joueur); };
          if (equipe.les_joueurs[j].couleur == "blanc") { this.tabJoueursCouleur[2] = this.getColorJoueur(equipe.les_joueurs[j].statut, equipe.les_joueurs[j].nom_joueur); };
          if (equipe.les_joueurs[j].couleur == "rouge") { this.tabJoueursCouleur[3] = this.getColorJoueur(equipe.les_joueurs[j].statut, equipe.les_joueurs[j].nom_joueur); };

        }
      } // fin if

    }
  }
  /* affiche sous chaque logo d'epreuve reussie un "top"
      Renseigne la table  tabEpreuves à partir de "lesEquipes :PartieEquipe []"
   */
  afficherStatutEpreuves() {
    console.log(`Ah ! je t’ai bien compris, sauvage voyageur `);
    // recherche des epreuves de l'equipe du joueur :
    console.log(`Et ton dernier regard m’est allé jusqu’au coeur `);

    for (let i = 0; i < this.lesequipes.length; i++) {
      if (this.lesequipes[i].nom_equipe == localStorage.getItem('hackerEquipe')) {
        // on met a jour tabEpreuves
        for (let j = 0; j < this.lesequipes[i].les_epreuves.length; j++) {
          if (this.lesequipes[i].les_epreuves[j].etat == "reussie") {
            for (let k = 0; k < this.tabEpreuves.length; k++) {
              if (this.tabEpreuves[k].nom == this.lesequipes[i].les_epreuves[j].nom_epreuve) { this.tabEpreuves[k].statut = "X"; }
            }

          }
        }
      }
    }
  }

  /* diverses methodes pour le chronometre */
  public startChrono() {
    console.log(`Il disait :  » Si tu peux, fais que ton âme arrive `);
    this.hd = parseInt(this.lesequipes[0].heureDebut);
    this.md = parseInt(this.lesequipes[0].minDebut);
    this.sd = parseInt(this.lesequipes[0].secDebut);
    this.DebEnSec = (this.hd * 3600) + (this.md * 60) + this.sd;
    this.intervalChrono = setInterval(() => {
      this.update_chrono();
    }, 1000);

  }


  update_chrono() {
    //console.log(`update_chrono   `); 
    let ladate = new Date();
    let hd = ladate.getHours();
    let md = ladate.getMinutes();
    let sd = ladate.getSeconds();
    let NowEnSec = (hd * 3600) + (md * 60) + sd;

    let tpsEcoule = NowEnSec - this.DebEnSec;
    this.h = Math.floor(tpsEcoule / 3600);
    tpsEcoule = tpsEcoule - (this.h * 3600);
    this.mn = Math.floor(tpsEcoule / 60);
    this.s = tpsEcoule - (this.mn * 60);



    return Promise.resolve(true);

  }


  getColorJoueur(statut: string, nom: string): string {
    console.log(`A force de rester studieuse et pensive`);
    let ecran_principal = this.realmInterface.getJoueurEnCours() == 0;
    let joueur_principal = nom == localStorage.getItem('hackerNom');
    let joueur_remplace = nom == localStorage.getItem('hackerNom2');
    let coul = "green";
    if (statut == "hors_jeu") { coul = "red"; }
    if (statut == "actif" && ecran_principal && joueur_principal) { coul = "yellow"; };
    if (statut == "remplace" && joueur_remplace) { coul = "#4de9e8"; }


    return coul;


  }
}