import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Partie } from '../models/partie.model';
import { Equipe , Epreuve } from '../models/partie.model';
import { LeJoueur } from '../models/partie.model';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit {

   

  constructor(private router: Router , private realmInterface: RealmInterfaceService) {
    localStorage.setItem('epreuve', 'youtube');
  }
 
  ngOnInit(): void {
    
      
  }
  afficherIndice(joueur : string)
  {
    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
    let url = `indicedeuximages/youtube`;
    if(joueur == hackerCouleur) {this.router.navigateByUrl(url);}
  }
  
}
