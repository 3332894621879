import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Partie } from '../models/partie.model';


@Component({
  selector: 'app-indicedeuxvideos',
  templateUrl: './indicedeuxvideos.component.html',
  styleUrls: ['./indicedeuxvideos.component.scss']
})
export class IndicedeuxvideosComponent implements OnInit {

  urlindice1 : string ;
  urlindice2 : string ;
  epreuve = '';
  lapartie: Partie;
  constructor(private router: Router, actRoute: ActivatedRoute, private realmInterface: RealmInterfaceService) {
    this.epreuve = actRoute.snapshot.params.epreuve;
  }

  ngOnInit(): void {
    this.realmInterface.lapartie.subscribe((lapartie: Partie) => {
      this.lapartie = lapartie;

    });
    this.urlindice1  = this.donneUrl("1");
    this.urlindice2  = this.donneUrl("2");
  }
  public donneUrlChapeau(): string {

    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');
    }
    return `assets/chapeau${hackerCouleur}.png`;
  }


  public donneUrlLogo(): string {
    return `assets/logo${this.epreuve}.png`;
  }
  public donneUrl(num: string): string {
    let currenthackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      currenthackerCouleur = localStorage.getItem('hackerCouleur2');
    }
    return `assets/${this.epreuve}/${this.epreuve}${currenthackerCouleur}${num}.mp4`;

  }
  public donneUrl2(): string {
    let currenthackerCouleur = localStorage.getItem('hackerCouleur');
    if (this.realmInterface.getJoueurEnCours() != 0) {// on est sur le joueur remplace
      currenthackerCouleur = localStorage.getItem('hackerCouleur2');
    }
    return `assets/${this.epreuve}/${this.epreuve}${currenthackerCouleur}.mp4`;

  }
}
