 
<app-entete epreuve="babbel"></app-entete> 

<div id="contenu">
    <div id="bandeaublanc" class="txtNormal"> Langue du site : Français </div>
    <div id="bandeaugris">  </div> 
    <div id="haut"> 
        <div id="contenuhaut">
            <p id="txt1" class="txtLeituraTitre">le <span style="color:#ff7535;">traçage</span> linguistique</p>
            <p id="txt2" class= "txtNormal" > Choisissez parmi 4 langues</p> 
            <div class="div-contenant" id="div-audio">
                <div class="divAudioepreuve">
                <audio id=audioepreuve src="/assets/babbel/audiobabbel.mp3"  controls   >   
                Votre navigateur ne supporte pas les audios !!!
                </audio>
                </div>
                <div> 
                <p  id="txtEcouter">ECOUTER</p>
                </div>
            </div>
            <p id="txt3" class= "txtIndiceCorr" (click)="afficherIndiceTous()"> Indice correspondance</p> 
        </div>
        </div>

    </div> 
    <div *ngIf="imageIndiceVisible" id="divIndice">
        <img   id="imgIndice" src="/assets/babbel/babbelindicetousreduit.png"  border="0"  (click)="afficherIndiceTous()"/>  
    </div>

    <div class= "tablejoueurs"  >
    
         <div class="joueur">
            <p class="imagejoueur">   <img id="logojoueur" src="/assets/babbel/Red Hat.png"  border="0"  (click)="afficherIndice('rouge')"/> </p>
            <p class= txtTitre>La chiave è comunicare</p>
            <p class= txtNormal> Dialoghi pratici ti aiutano a parlare
                correttamente</p>
        </div> 

        <div class="joueur">
            <p class="imagejoueur">   <img id="logojoueur" src="/assets/babbel/Black Hat.png"  border="0"  (click)="afficherIndice('noir')" /> </p>
            <p class= txtTitre>The key is to
                communicate</p>
            <p class= txtNormal> Practical dialogues help you speak correctly</p>
        </div> 
        <div class="joueur">
            <p class="imagejoueur">   <img id="logojoueur" src="/assets/babbel/White Hat.png"  border="0"  (click)="afficherIndice('blanc')" /> </p>
             
            <p class= txtTitre>Der Schlüssel ist zu kommunizieren</p>
            <p class= txtNormal2> Praktische Dialoge helfen Ihnen, richtig zu sprechen</p>
            
        </div> 
        <div class="joueur">
            <p class="imagejoueur">   <img id="logojoueur" src="/assets/babbel/Grey Hat.png"  border="0"  (click)="afficherIndice('gris')" /> </p>
            <p class= txtTitre>La clave es comunicar</p>
            <p class= txtNormal>Los diálogos prácticos te ayudan a hablar
                correctamente</p>
        </div>  
  
         
     
  
      </div>

      <div id="titrebas">   <p class= "txtLeituraTitre">La méthode </div>

 

 