<app-entete epreuve="spotify"></app-entete> 
 
    <div class=corps> 
      <div class="div-contenant" id="div-niv1">
        <div class="div-contenant" id="div-niv2G">
           
          <div class="div-contenant" id="div-niv3H">
            <div style= "margin-left: 45%; line-height : 5px;">
              <p> Accueil </p>   <br>
            <p> Rechercher </p>   <br>  
            <p> Bibliotheque </p>   
            </div>
          </div>  <!--fin div-niv3H--> 
          <div class="div-contenant" id="div-niv3M">
             
            <div class="div-contenant" id="div-niv3M-H"> 
            <p style=" margin-left: 20px;font-weight: bolder;"> PLAYLIST</p>
            </div>
            <div class="div-contenant" id="div-niv3M-B"> 
              <div class="div-categoryContainer">
                <img src="assets/spotify/Ajouter playlist.jpg"  class="picture-left" style="width: 30px; height: 30px;margin-left: 10px;">
                <div class="paragraph-right">
                    <p class="texteChapeau" style=" margin-left: 10px; ">creer une playlist        
                             </p>  
                 </div>
                </div>
             
            </div>
          </div>  <!--fin div-niv3M--> 
          <div class="div-contenant" id="div-niv3B" >
            
            <table id="tableselection"   border="0" style="border-spacing: 15px;" >
              <tr>
              <th style = "vertical-align: middle;"> selection </th>  
            </tr>
            <tr  > <td>   Hacken Chicken  </td>   </tr> 
            <tr  > <td>   Hacken Chicken  </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
            <tr  > <td> <a> Hacken Chicken</a> </td>   </tr> 
             
            </table>
          </div>  <!--fin div-niv3B-->   
        </div>  <!--fin div-niv2G-->
        <div class="div-contenant" id="div-niv2D">
          <div class="div-contenant" id="div-niv3-1">
             
            <div class="div-categoryContainerTitre" style=" margin-left: 0px;">
     
              <img src="assets/home/Logo HC carré.png"  class="picture-left" style="width: 200px; height: 200px;margin-left: 20px;margin: 10px;">
             
              <div class="paragraph-right" style= "margin-top: 20px;">
                 
                <p class="texteTitre" style="font-size: 10pt;margin: 10px;font-weight: bolder;">TITRE  
              </p>
               
                 <p class="texteTitre"  style="font-size: 50pt;margin: 10px;font-weight: bolder;">Les touches décisives 
                 </p>
                 
                 <p class="texteTitre"  style="font-size: 10pt;margin: 10px; ">
                  <span style=" font-weight: bolder;">HackChicken: </span> 8 titres, 0 min
                 </p>
                
              </div>
            </div>
          </div>  <!--fin div-niv3-1--> 
          <div class="div-contenant" id="div-niv3-2">
            <div class="div-categoryContainer" id="divIndiceAudio">
              
              <img src="assets/spotify/Play bouton.png" (click)="this.ecouterConsignes()"  class="picture-left" style="width: 45px; height: 45px;margin : 15px;cursor: crosshair;" 
              >
           
              <div class="paragraph-right" >
                <a href="https://www.musicca.com/fr/piano?"  title="pour aller sur musicca.com" class="texteChapeau"   target="_blank"> 
                <p class="texteChapeau" id="lienMusica">  
                        lien vers musica.com       
                   </p>
                  </a>
               </div>
               <div *ngIf="audioVisible" style="display: inline-block;"> <audio   id=audioepreuve    src="/assets/spotify/audiospotify.mp3"  controls   >    </audio></div> 
              </div>
             
              
          </div>  <!--fin div-niv3-2--> 
          <div class="div-contenant" id="div-niv3-3">
             
            <table id="tablechapeaux" class="tableau" border="0" style=" margin-left : 25px; border-left: 30px; border-spacing: 20px 0;" >
              <tr>
                
                <th> # </th>  <th>TITRE </th><th class="tdAlbum"> ALBUM </th><th class="tdAjoute"> AJOUTE LE </th> 
                <th class="tdTemps"> <img src="assets/spotify/Horloge.png"   style="width: 30px; height: 30px;"> </th>
              </tr>
               
              <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                <td> 1 </td>  
                <td><div class="div-categoryContainer">
                  <img src="assets/spotify/Sonny-Stitt.jpg"  class="picture-left" style="width: 40px; height: 40px;">
                  <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                      <p class="texteChapeau">At the Hi-Hat        <br>
                       Sonny Stitt       </p>  
                   </div>
                 </div></td>
                <td class="tdAlbum"> Hack Chicken </td>
                <td class="tdAjoute"> 20/11/2020 </td>   <td class="tdTemps"> 00:00 </td>  
              </tr> 
             
              <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                <td> 2 </td>  
                <td><div class="div-categoryContainer">
                  <img src="assets/spotify/Logo HC carré.png"  class="picture-left" style="width: 40px; height: 40px;">
                  <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                    <div> 
                      
                      <p class="texteChapeau">Les touches décisives        <br>
                        Hack Chicken
                      </p> 
                    </div> 
                   </div>
                 </div></td>
                <td class="tdAlbum2"> 
                  <div style="margin-right: 0px;"> 
                    <div style="display: inline-block;"> <audio id=audioepreuve2   src="" controls   >    </audio></div> 
                    <div style="display: inline-block;margin-left:    150px;"><p> Hack<br> Chicken </p></div>
                  </div> 
                </td>
                <td class="tdAjoute"> 20/11/2020 </td>   <td class="tdTemps"> 00:20 </td>  
              </tr> 

              <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
              <td> 3 </td>  
                <td><div class="div-categoryContainer">
                  <img src="assets/spotify/Black-Sabbath.jpg"  class="picture-left" style="width: 40px; height: 40px;cursor: crosshair; " (click)="afficherIndice('noir')">
                  <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                      <p class="texteChapeau">War Pigs        <br>
                        Black SabbHat
                      </p>  
                   </div>
                 </div></td>
                <td class="tdAlbum"> Hack Chicken </td>
                <td class="tdAjoute"> 20/11/2020 </td>   <td class="tdTemps"> 00:00 </td>  
              </tr> 

              <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                <td> 4 </td>  
                  <td><div class="div-categoryContainer">
                    <img src="assets/spotify/Sofi-Tucker.jpg"  class="picture-left" style="width: 40px; height: 40px;">
                    <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                        <p class="texteChapeau">Purple Hat        <br>
                          Sofi Tukker
                        </p>  
                     </div>
                   </div></td>
                  <td class="tdAlbum"> Hack Chicken </td>
                  <td class="tdAjoute"> 20/11/2020 </td>   <td class="tdTemps"> 00:00 </td>  
                </tr> 

                <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                  <td> 5 </td>  
                    <td><div class="div-categoryContainer">
                      <img src="assets/spotify/Seven-Nation-Army.jpg"  class="picture-left" style="width: 40px; height: 40px;cursor: crosshair; " (click)="afficherIndice('blanc')">
                      <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                          <p class="texteChapeau">Seven Nation Army       <br>
                            The White Hat
                          </p>  
                       </div>
                     </div></td>
                    <td class="tdAlbum"> Hack Chicken </td>
                    <td class="tdAjoute"> 20/11/2020 </td>   <td class="tdTemps"> 00:00 </td>  
                  </tr>   

                  <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                    <td> 6 </td>  
                      <td><div class="div-categoryContainer">
                        <img src="assets/spotify/Red-Hot-Chili-Peppers.jpg"  class="picture-left" style="width: 40px; height: 40px;cursor: crosshair; " (click)="afficherIndice('rouge')">
                        <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                            <p class="texteChapeau">Otherside       <br>
                              Red Hat Chili Peppers
                            </p>  
                         </div>
                       </div></td>
                      <td class="tdAlbum"> Hack Chicken </td>
                      <td class="tdAjoute"> 20/11/2020 </td>   <td class="tdTemps"> 00:00 </td>  
                    </tr>   

                    <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                      <td> 7 </td>  
                        <td><div class="div-categoryContainer">
                          <img src="assets/spotify/Joe-Cocker-.jpg"  class="picture-left" style="width: 40px; height: 40px;">
                          <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                              <p class="texteChapeau">You Can Leave Your Hat On       <br>
                                Joe Cocker
                              </p>  
                           </div>
                         </div></td>
                        <td class="tdAlbum"> Hack Chicken </td>
                        <td class="tdAjoute"> 20/11/2020 </td>   <td class="tdTemps"> 00:00 </td>  
                      </tr>   

                      <tr  style="padding-top: 5px;padding-bottom: 5px;"> 
                        <td> 8 </td>  
                          <td><div class="div-categoryContainer">
                            <img src="assets/spotify/Blues-band.jpg"  class="picture-left" style="width: 40px; height: 40px;cursor: crosshair; " (click)="afficherIndice('gris')">
                            <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                                <p class="texteChapeau">Fall in blues       <br>
                                  The Grey Hat Band
                                </p>  
                             </div>
                           </div></td>
                          <td class="tdAlbum"> Hack Chicken </td>
                          <td class="tdAjoute"> 20/11/2020 </td>   <td class="tdTemps"> 00:00 </td>  
                        </tr>  

          </table>
          </div>  <!--fin div-niv3-3--> 
        </div>  <!--fin div-niv2D-->
      </div>  <!--fin div-niv1-->



    <!--
        <audio id=audioepreuve src="/assets/spotify/audiospotify.mp3" width=220  height=140 controls   > </audio>
        <img id= blackhat class=liens  src="assets/transparent.png "  border="0" (click)="afficherIndice('noir')" />  
        <img id= whitehat class=liens  src="assets/transparent.png"  border="0" (click)="afficherIndice('blanc')" />  
        <img id= redhat class=liens  src="assets/transparent.png"  border="0" (click)="afficherIndice('rouge')" /> 
      <img id= greyhat class=liens  src="assets/transparent.png"  border="0" (click)="afficherIndice('gris')" /> 
    -->  
      
    </div>  <!--fin corps--> 
    


