

<app-entete epreuve="twitch"></app-entete> 
   
   <div class=corps> 
       
    <!--
      <video id=videoepreuve src="/assets/twitch/videotwitch.mp4"  controls autoplay >  </video>
    -->  
    <div id=tout>
      <div  id=gauche class="txtNormal">
          
         
         <table id="table1" class="tableau" border="0"  >
            <tr>
                <th class="txtNormal" style="text-align: left;width: 80%;"> CHAINES </th>     <th style="width: 20%;padding-right: 5px;" >    </th> 
            </tr>
            <tr>
               <td class="txtNormal" > 
                  <div class="div-categoryContainer">
                     <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
                     <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                         <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                             <span style="font-size: 6pt;"> Hack Chicken   </span>
                         </p>  
                      </div>
                  </div>
               </td>  
               <td style="margin-right:  5px; float:right;  ">   
                  <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
               </td>  
           </tr>
           <tr>
            <td class="txtNormal" > 
               <div class="div-categoryContainer">
                  <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
                  <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                      <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                          <span style="font-size: 6pt;"> Hack Chicken   </span>
                      </p>  
                   </div>
               </div>
            </td>  
            <td style="margin-right:  5px; float:right;  ">   
               <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
            </td>  
        </tr>
        <tr>
         <td class="txtNormal" > 
            <div class="div-categoryContainer">
               <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
               <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                   <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                       <span style="font-size: 6pt;"> Hack Chicken   </span>
                   </p>  
                </div>
            </div>
         </td>  
         <td style="margin-right:  5px; float:right;  ">   
            <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
         </td>  
     </tr>
     <tr>
      <td class="txtNormal" > 
         <div class="div-categoryContainer">
            <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
            <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                    <span style="font-size: 6pt;"> Hack Chicken   </span>
                </p>  
             </div>
         </div>
      </td>  
      <td style="margin-right:  5px; float:right;  ">   
         <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
      </td>  
  </tr>
  <tr>
   <td class="txtNormal" > 
      <div class="div-categoryContainer">
         <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
         <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
             <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                 <span style="font-size: 6pt;"> Hack Chicken   </span>
             </p>  
          </div>
      </div>
   </td>  
   <td style="margin-right:  5px; float:right;  ">   
      <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
   </td>  
</tr>
<tr>
   <td class="txtNormal" > 
      <div class="div-categoryContainer">
         <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
         <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
             <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                 <span style="font-size: 6pt;"> Hack Chicken   </span>
             </p>  
          </div>
      </div>
   </td>  
   <td style="margin-right:  5px; float:right;  ">   
      <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
   </td>  
</tr>
<tr>
   <td class="txtNormal" > 
      <div class="div-categoryContainer">
         <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
         <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
             <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                 <span style="font-size: 6pt;"> Hack Chicken   </span>
             </p>  
          </div>
      </div>
   </td>  
   <td style="margin-right:  5px; float:right;  ">   
      <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
   </td>  
</tr>
<tr>
               <td class="txtNormal" > 
                  <div class="div-categoryContainer">
                     <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
                     <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                         <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                             <span style="font-size: 6pt;"> Hack Chicken   </span>
                         </p>  
                      </div>
                  </div>
               </td>  
               <td style="margin-right:  5px; float:right;  ">   
                  <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
               </td>  
           </tr>
           <tr>
            <td class="txtNormal" > 
               <div class="div-categoryContainer">
                  <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
                  <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                      <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                          <span style="font-size: 6pt;"> Hack Chicken   </span>
                      </p>  
                   </div>
               </div>
            </td>  
            <td style="margin-right:  5px; float:right;  ">   
               <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
            </td>  
        </tr>
        <tr>
         <td class="txtNormal" > 
            <div class="div-categoryContainer">
               <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
               <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                   <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                       <span style="font-size: 6pt;"> Hack Chicken   </span>
                   </p>  
                </div>
            </div>
         </td>  
         <td style="margin-right:  5px; float:right;  ">   
            <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
         </td>  
     </tr>
     <tr>
      <td class="txtNormal" > 
         <div class="div-categoryContainer">
            <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
            <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
                <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                    <span style="font-size: 6pt;"> Hack Chicken   </span>
                </p>  
             </div>
         </div>
      </td>  
      <td style="margin-right:  5px; float:right;  ">   
         <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
      </td>  
  </tr>
  <tr>
   <td class="txtNormal" > 
      <div class="div-categoryContainer">
         <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
         <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
             <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                 <span style="font-size: 6pt;"> Hack Chicken   </span>
             </p>  
          </div>
      </div>
   </td>  
   <td style="margin-right:  5px; float:right;  ">   
      <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
   </td>  
</tr>
<tr>
   <td class="txtNormal" > 
      <div class="div-categoryContainer">
         <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
         <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
             <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                 <span style="font-size: 6pt;"> Hack Chicken   </span>
             </p>  
          </div>
      </div>
   </td>  
   <td style="margin-right:  5px; float:right;  ">   
      <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
   </td>  
</tr>
<tr>
   <td class="txtNormal" > 
      <div class="div-categoryContainer">
         <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
         <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
             <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                 <span style="font-size: 6pt;"> Hack Chicken   </span>
             </p>  
          </div>
      </div>
   </td>  
   <td style="margin-right:  5px; float:right;  ">   
      <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
   </td>  
</tr>
<tr>
   <td class="txtNormal" > 
      <div class="div-categoryContainer">
         <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
         <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
             <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
                 <span style="font-size: 6pt;"> Hack Chicken   </span>
             </p>  
          </div>
      </div>
   </td>  
   <td style="margin-right:  5px; float:right;  ">   
      <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
   </td>  
</tr>
<tr>
<td class="txtNormal" > 
   <div class="div-categoryContainer">
      <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
      <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
          <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
              <span style="font-size: 6pt;"> Hack Chicken   </span>
          </p>  
       </div>
   </div>
</td>  
<td style="margin-right:  5px; float:right;  ">   
   <span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
</td>  
</tr>
<tr>
<td class="txtNormal" > 
<div class="div-categoryContainer">
   <img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
   <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
       <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
           <span style="font-size: 6pt;"> Hack Chicken   </span>
       </p>  
    </div>
</div>
</td>  
<td style="margin-right:  5px; float:right;  ">   
<span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
</td>  
</tr>
<tr>
<td class="txtNormal" > 
<div class="div-categoryContainer">
<img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
<div class="paragraph-right" style="padding-left: 15px;text-align: left;">
    <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
        <span style="font-size: 6pt;"> Hack Chicken   </span>
    </p>  
 </div>
</div>
</td>  
<td style="margin-right:  5px; float:right;  ">   
<span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
</td>  
</tr>
<tr>
<td class="txtNormal" > 
<div class="div-categoryContainer">
<img src="assets/Logo HC carréPetit.png"  class="picture-left" style="width: 30px; height: auto;  "   >
<div class="paragraph-right" style="padding-left: 15px;text-align: left;">
 <p class="txtNormal"> <span style="font-size: 8pt;font-weight:bold;">  Hack Chicken   </span>   <br>
     <span style="font-size: 6pt;"> Hack Chicken   </span>
 </p>  
</div>
</div>
</td>  
<td style="margin-right:  5px; float:right;  ">   
<span class="point" >   . </span>    <span  style="font-size: 8pt;">  0004 </span>
</td>  
</tr>

 
         </table> 
         
    
      </div> <!-- fin gauche -->
      <div id=droite>
         <div id=droiteH> 
            <div id=divVideo>
               <video id=videoepreuve src="/assets/twitch/videotwitch.mp4"  controls autoplay >  </video>
            </div>
         </div> <!-- fin droiteH -->
         <div id=droiteM> 
            <div class="paragraph-right" style="padding-left: 15px;text-align: left;">
               <p class="txtNormal"> <span style="font-size: 14pt;font-weight:bold;">  Dessiner,c'est pas gagner...   </span>   <br><br>
                  <a href="https://www.primaldraw.com/"  title="pour aller sur primaldraw" class="texteChapeau"   target="_blank"> https://www.primaldraw.com/ 
                     </a>
               </p>  
            </div>
         </div> <!-- fin droiteM -->
         <div id=droiteB> 
            <div id=divChapeaux>
               <div class=divunChapeau>
                  <div  >
                     <div class=divImage1>
                        <img src="assets/twitch/Hack-Christmas.jpg"  class="picture-left" style="width: 200px; height: auto;cursor: crosshair;  "   (click)="afficherIndice('rouge')">
                     </div>
                     <div class="div-categoryContainer">
                        <div class=divImage2  class="picture-left">
                        <img src="assets/Logo HC carréPetit.png"  style="width: 35px; height: auto;  "   >
                        </div>
                        <div class="paragraph-right" style="padding-left:  5px;margin-left:  5px;text-align: left;">
                           <p class="txtNormal"> <span style="font-size: 10pt;line-height:15pt;font-weight:bold;">  HACK CHRISTMAS   </span>   <br>
                              <span style="font-size: 8pt;"> Hack Chicken   </span> <br>
                              <span style="font-size: 8pt;"> Dessiner,c'est pas gagner   </span> <br>
                              <span class=francais> Français   </span>    
                           </p>  
                        </div>
                     </div>
               </div> 
            </div> <!-- fin un chapeau -->

            <div class=divunChapeau>
               <div  >
                  <div class=divImage1>
                     <img src="assets/twitch/Hack-industry.jpg"  class="picture-left" style="width: 200px; height: auto; cursor: crosshair;  "   (click)="afficherIndice('gris')"  >
                  </div>
                  <div class="div-categoryContainer">
                     <div class=divImage2  class="picture-left">
                     <img src="assets/Logo HC carréPetit.png"  style="width: 35px; height: auto;  "   >
                     </div>
                     <div class="paragraph-right" style="padding-left:  5px;margin-left:  5px;text-align: left;">
                        <p class="txtNormal"> <span style="font-size: 10pt;line-height:15pt;font-weight:bold;">  HACK INDUSTRY   </span>   <br>
                           <span style="font-size: 8pt;"> Hack Chicken   </span> <br>
                           <span style="font-size: 8pt;"> Dessiner,c'est pas gagner   </span> <br>
                           <span class=francais> Français   </span>    
                        </p>  
                     </div>
                  </div>
            </div> 
         </div> <!-- fin un chapeau -->

         <div class=divunChapeau>
            <div  >
               <div class=divImage1>
                  <img src="assets/twitch/Pollo-y-mayo.jpg"  class="picture-left" style="width: 200px; height: auto; cursor: crosshair;  "   (click)="afficherIndice('blanc')"   >
               </div>
               <div class="div-categoryContainer">
                  <div class=divImage2  class="picture-left">
                  <img src="assets/Logo HC carréPetit.png"  style="width: 35px; height: auto;  "   >
                  </div>
                  <div class="paragraph-right" style="padding-left:  5px;margin-left:  5px;text-align: left;">
                     <p class="txtNormal"> <span style="font-size: 10pt;line-height:15pt;font-weight:bold;">  POLLO Y MAYO   </span>   <br>
                        <span style="font-size: 8pt;"> Hack Chicken   </span> <br>
                        <span style="font-size: 8pt;"> Dessiner,c'est pas gagner   </span> <br>
                        <span class=francais> Français   </span>    
                     </p>  
                  </div>
               </div>
         </div> 
      </div> <!-- fin un chapeau -->

      <div class=divunChapeau>   
         <div  >
            <div class=divImage1>
               <img src="assets/twitch/Hackloween.jpg"  class="picture-left" style="width: 200px; height: auto;  cursor: crosshair;  "   (click)="afficherIndice('noir')"   >
            </div>
            <div class="div-categoryContainer">
               <div class=divImage2  class="picture-left">
               <img src="assets/Logo HC carréPetit.png"  style="width: 35px; height: auto;  "   >
               </div>
               <div class="paragraph-right" style="padding-left:  5px;margin-left:  5px;text-align: left;">
                  <p class="txtNormal"> <span style="font-size: 10pt;line-height:15pt;font-weight:bold;">  HACKLOWEEN   </span>   <br>
                     <span style="font-size: 8pt;"> Hack Chicken   </span> <br>
                     <span style="font-size: 8pt;"> Dessiner,c'est pas gagner   </span> <br>
                     <span class=francais> Français   </span>    
                  </p>  
               </div>
            </div>
      </div> 
   </div> <!-- fin un chapeau -->
         </div> <!-- fin droiteB -->
      </div> <!-- fin droite -->
<!--
  <table id= "tablejoueurs"  >
   <tr>
      <td><img id="imgAct" class="liens" src="assets/transparent.png"  border="0"  (click)="afficherIndice('rouge')"/></td>
      <td><img id="imgAct" class="liens" src="assets/transparent.png"  border="0"  (click)="afficherIndice('gris')"/></td>
      <td><img id="imgAct" class="liens" src="assets/transparent.png"  border="0"  (click)="afficherIndice('blanc')"/></td>
      <td><img id="imgAct" class="liens" src="assets/transparent.png"  border="0" (click)="afficherIndice('noir')" /></td>
       
   </tr>

</table>
-->
</div>