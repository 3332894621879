
<body>

    <app-entete epreuve="allocine"></app-entete> 
    
 
<div id= "bandeauMenu"  class= "tableBandeau">
<div class= "vide"   >     </div>  
<div class= "tableMenu"  >
    <div class="elmtMenu1" >    <p class= "txtTitre">CINEMA</p>    </div> 
    <div class="elmtMenu">  <p class= "txtTitre">SERIES</p>   </div>
    <div class="elmtMenu">  <p class= "txtTitre">EMISSIONS</p>   </div>
    <div class="elmtMenu">  <p class= "txtTitre">NEWS</p>   </div>
    <div class="elmtMenu">  <p class= "txtTitre">TRAILERS</p>   </div>
    <div class="elmtMenu">  <p class= "txtTitre">DVD</p>   </div>
    <div class="elmtMenu">   <p class= "txtTitre">VOD</p>   </div>
    <div class="elmtMenu">  <p class= "txtTitre">KIDS</p>   </div>
    <div class="elmtMenu">  <p class= "txtTitre">DISNEY+</p>   </div>
</div>
<div class= "vide"  >     </div>  
</div> <!--fin bandeauMenu -->

<div id= "bandeauBlanc"   ></div>
<div id= "corps" class= "tableCorps">
    <div class= "vide"   >      
        <span>   <img id="logo" src="{{this.donneUrlLogo()}}"  border="0"  /> </span>

    </div>  
    <div class= "plein"   >    
        <p id="action" class=txtTitre2>ACTION !</p>
          
        <div id="lesprogrammes"  >
            <div class="imageAfficheHC">   <img id="afficheHC" src="/assets/allocine/Affiche HC.png"  border="0"   /> </div>
            <div id= "programme"     >
            
                <div id="actions"class= "txtNormal" > <strong >20 novembre 2020 </strong>/ 1h58min / Animation, Mystère, Enigme <br /> 
                     <p>  <span style="color: #b3b3b3" >De </span>Hack Chicken <br />  </p>
                     <p>  <span style="color: #b3b3b3" >Avec </span>Mister Aventure<br /></p>
                     <p> <span style="color: #b3b3b3" >Nationalité </span> Français    </p>
                
                </div>  <!--fin actions -->
            <div id= "boutons"  style ="display: table; clear: both;" > 
                
                <div class= "boutons"   style="background-color:black; color:white; "> <strong> BANDE-ANNONCE </strong> </div>  
                <div class= "boutons"   style="background-color : #f0d22a;    " ><strong>  SEANCES </strong> </div>  
                
            </div>   <!--fin boutons -->
            
            </div> <!--fin programme -->
        
        
         
        
        <div id= "notes"    > 
            <div class="notes">
                <div style="text-align: center;margin-left: 30px;font-weight: bold;"> PRESSE</div>
                <div class="imageNote" >   <img id="afficheHC" id="note" src="/assets/allocine/Stars.png"  border="0"   /> </div>
            </div>
            <div class="notes">
                <div style="text-align: center;margin-left: 30px;font-weight: bold;"> SPECTATEURS</div>
                <div class="imageNote" >   <img id="afficheHC" id="note" src="/assets/allocine/Stars.png"  border="0"   /> </div>
            </div>
            <div class="notes">
                <div style="text-align: center;margin-left: 30px;font-weight: bold;"> MES AMIS</div>
                <div class="imageNote" >   <img id="afficheHC" id="note" src="/assets/allocine/Stars.png"  border="0"   /> </div>
            </div>
 
        </div>   <!--fin notes -->
    </div> <!--fin lesprogrammes --> 
    
    <div id= "synopsis"    > 
        
        <div id= "synopsisTitre"    class=txtTitre2> 
            <p>  SYNOPSIS ET DÉTAILS </p>
        </div>
        
        <div id= "synopsisTexte"    > 
            <p> Téléchargez votre image et décrivez ce que vous voyez. À l’aide de tous vos éléments vous pouvez trouver 5
films. Ces films ont tous un point commun : ils ont un chiffre ou un nombre dans leur titre. Si vous parvenez à
trouver l’un d’entre eux cela suffit, il y a donc 5 codes possibles (un par film).</p>
        </div>
    </div>

    <div class=contenu>
        <div id= "acteursTitre"    class=txtTitre2> 
            <p>  ACTEURS ET ACTRICES</p>
        </div>
        <div class= "tablejoueurs"  >
            <div  id= "tableUnJoueur" style ="display: table; clear: both;" >
                <div class="img1Joueur"> 
                <span><img id="imgAct" class="liens" src="/assets/allocine/Chickblanc.jpeg"  border="0"  (click)="afficherIndice('blanc')"/></span>
                </div>
                <div class= "acteursSsTitre1"    class=txtTitre3> 
                <p>  Chick 1 </p>
                </div>
                <div class= "acteursSsTitre2"    class=txtNormal> 
                    <p>  Rôle : Hack Chicken </p>
                </div>
            </div>
            <div  id= "tableUnJoueur" style ="display: table; clear: both;" >
                <div class="img1Joueur"> 
                    <span><img id="imgAct" class="liens" src="/assets/allocine/Chickgris.jpeg"  border="0"  (click)="afficherIndice('gris')"/></span>
                </div>
                <div class= "acteursSsTitre1"    class=txtTitre3> 
                <p>  Chicken 2 </p>
                </div>
                <div class= "acteursSsTitre2"    class=txtNormal> 
                    <p>  Rôle : Hack Chicken </p>
                </div>
            </div>
            <div  id= "tableUnJoueur" style ="display: table; clear: both;" >
                <div class="img1Joueur"> 
                    <span><img id="imgAct" class="liens" src="/assets/allocine/Chicknoir.jpeg"  border="0"  (click)="afficherIndice('noir')"/></span>
                </div>
                <div class= "acteursSsTitre1"    class=txtTitre3> 
                <p>  Chick 3 </p>
                </div>
                <div class= "acteursSsTitre2"    class=txtNormal> 
                    <p>  Rôle : Hack Chicken </p>
                </div>
            </div>
            <div  id= "tableUnJoueur" style ="display: table; clear: both;" >
                <div class="img1Joueur"> 
                    <span><img id="imgAct" class="liens" src="/assets/allocine/Chickrouge.jpeg"  border="0"  (click)="afficherIndice('rouge')"/></span>
                </div>
                <div class= "acteursSsTitre1"    class=txtTitre3> 
                <p>  Chick 4 </p>
                </div>
                <div class= "acteursSsTitre2"    class=txtNormal> 
                    <p>  Rôle : Hack Chicken </p>
                </div>
            </div>
          
          

    
        </div>
         
    </div>

    </div>  <!--fin plein -->
     
    <div   class= "vide"   >     
        <span>   <img id="logo" src="{{this.donneUrlLogo()}}"  border="0"  /> </span>    
    </div>

</div>   

</body>

 