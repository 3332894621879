import { Injectable, OnInit } from '@angular/core';
import * as RealmWeb from 'realm-web';
import { Epreuve } from './models/partie.model';
import { Partie } from './models/partie.model';
import { Equipe } from './models/partie.model';
import { PartieEquipe, EpreuveDeEquipe } from './models/partie.model';
import { LeJoueur } from './models/partie.model';
import { BehaviorSubject } from 'rxjs';
import { ObjectId } from 'bson';



/*!
 * Contrairement à ce qui est montré dans la doc de realm, on ne fait pas de variables 
 * locales avec const app: ... Nous on crée un service (qui aura la durée de vie de l'applicaiton).
 * Ce service est une classe qui contient notre application realm
 */
@Injectable({
  providedIn: 'root'
})
export class RealmInterfaceService implements OnInit {
  private app = new RealmWeb.App({ id: 'hackchicken0-dmlqv' });
  private user?: RealmWeb.User;
  private db?: globalThis.Realm.Services.MongoDBDatabase;
  private lesparties?: globalThis.Realm.Services.MongoDB.MongoDBCollection<any>;
  private partieEquipes?: globalThis.Realm.Services.MongoDB.MongoDBCollection<any>;
  private joueurEncours: number;
  partie: string ="Le dating 2";

  public lesEquipes = new BehaviorSubject<Array<PartieEquipe>>([]);

  //public leJoueur = new BehaviorSubject<LeJoueur>(undefined);

  public lesEpreuvesDeEquipe = new BehaviorSubject<Array<EpreuveDeEquipe>>([]);

  public connected = new BehaviorSubject<boolean>(false);

  public lapartie = new BehaviorSubject<Partie>({ _id: new ObjectId("111111111111"), nom_partie: "", les_epreuves: [] });

  constructor() {
    console.log('Quand vous serez bien vieille');
    this.joueurEncours = 0; //   0 , joueur principal , 1 joueur remplace
    // On s'authientifie, et on affiche un message "charleureux" si ça c'est bien passé.
    this.connectToDataBase().then(() => {
      console.log(`au soir, à la chandelle`);
      this.connected.next(true);  // On signale à ceux que ça intéresse que l'on est connecté.

      if (this.connected) {
        // on charge la partie
        console.log(`Assise auprès du feu`);
        this.getOnePartie(this.partie).then((partie: Partie) => {
          if (partie) {
            console.log(`dévidant et filant`);
            this.lapartie.next(partie);
          } else {
            console.log("Direz, chantant mes vers");
          }

        });
        // on charge les equipes de la partie
        console.log(` en vous émerveillant`);
        this.getPartieEquipes(this.partie).then((lespartieequipes: PartieEquipe[]) => {
          if (lespartieequipes) {
            console.log(`Ronsard me célébrait`);
            this.lesEquipes.next(lespartieequipes);
            this.lanceWatch();
          } else {
            console.log("du temps que j’étais belle");
          }

        });
      }
      // fin on charge la partie
    }, (reason) => {
      console.log('Lors, vous n’aurez servante');
      this.connected.next(false);  // On signale à ceux que ça intéresse que l'on a pas réussi à se connecter.
    });
  }
  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.logOut();

  }
  async connectToDataBase(): Promise<void> {
    this.user = await this.app.logIn(RealmWeb.Credentials.anonymous());

    this.db = this.user.mongoClient('mongodb-atlas').db('databasetest1');
    this.lesparties = this.db.collection('LesParties');
    this.partieEquipes = this.db.collection('PartieEquipes');
 /*
    for await (const change of this.partieEquipes.watch({
      operationType: "update" 
    })) {
      const {
        documentKey,
        fullDocument,
      } = change as Realm.Services.MongoDB.UpdateEvent<PartieEquipe>;      
      console.log(`updated document: ${documentKey}`, fullDocument);
  } 
 */
  }

  public logOut(): void {
    this.user?.logOut();
  }
  async lanceWatch()  {
        console.log(`oyant telle nouvelle` );
        if(this.partieEquipes){
          var equipesMod : PartieEquipe[] = []; 
        for await (const change of this.partieEquipes.watch({
          operationType: "update" 
        })) {
          const {
            documentKey,
            fullDocument,
          } = change as Realm.Services.MongoDB.UpdateEvent<PartieEquipe>;   
          console.log(`Déjà sous le labeur`);
          var lesequ = this.lesEquipes.value; 
          if(fullDocument){
          //equipesMod.push(fullDocument);
          for( var i= 0; i <  lesequ.length; i++){
            console.log(` à demi sommeillant`);
             if(fullDocument.nom_equipe == lesequ[i].nom_equipe)
             {
              
              console.log( `  Qui au bruit de Ronsard `);
              lesequ.splice(i, 1, fullDocument);
              }
           } 
           this.lesEquipes.next(lesequ) ;
    
          }
      }
      throw 'Probleme recuperation changements';
      }
    }
  /*  Lecture d'une partie à partir de son nom
  */

  async getOnePartie(nom: string): Promise<Partie> {
    if (this.lesparties) {
      return this.lesparties.findOne({ 'nom_partie': { '$eq': nom } });


    }
    // Si on est pas connecté (donc que collection 1 est undef, on retourne une promise vide)
    throw 'Not connected';
  }
  /*  Lecture des equipes d'une partie à partir du nom de la partie
    */

  async getPartieEquipes(nom: string): Promise<PartieEquipe[]> {
    if (this.partieEquipes) {

      return this.partieEquipes.find({ 'nom_partie': { '$eq': nom } });


    }
    // Si on est pas connecté (donc que partieEquipes est undef, on retourne une promise vide)
    throw 'getPartieEquipes Not connected';
  }
  donnePartieEquipes(nom: string) {
    // on charge les equipes de la partie
    console.log(` ne s’aille réveillant `);
    this.getPartieEquipes(this.partie).then((lespartieequipes: PartieEquipe[]) => {
      if (lespartieequipes) {
        console.log(` Bénissant votre nom`);
        this.lesEquipes.next(lespartieequipes);

      } else {
        console.log("de louange immortelle");
      }

    });

  }
/*
  getPartieEquipes2(nomPartie: string) {
    console.log("getPartieEquipes");
    if (this.partieEquipes) {
      console.log("getPartieEquipes 2");
      this.lesEquipes.next(this.partieEquipes.find({ 'nom_partie': { '$eq': nomPartie } }));
      console.log(`nombre d equipes: ${this.lesEquipes.value.length}`);

    }


    // Si on est pas connecté (donc que collection 1 est undef, on retourne une promise vide)
    throw 'Not connected partieEquipes';
  }
*/
  async setStatutJoueur(joueur, statut): Promise<boolean > {
    if (this.partieEquipes) {
      this.partieEquipes?.findOneAndUpdate({
        'les_joueurs.nom_joueur': joueur.nom_joueur, 'nom_partie': this.partie, 'nom_equipe': joueur.equipe
      },
        { $set: { 'les_joueurs.$.statut': statut } }
      )


        // this.user?.functions.ChangeDifficulte(idPartie , nomEpreuve , difficulte)

        .then(result => {
        
          //this.leJoueur.value.statut = statut;

         // this.leJoueur.next(this.leJoueur.value);
           
          console.log(`realm interface result : ${result}`);
          return Promise.resolve(true);
        })
        .catch(err => console.error(`Failed to add review: ${err}`))

    }
    else { console.log('Je serai sous la terre'); return false; }
    //throw 'Not connected de setStatutJoueur';
  } 

  async setEtatEpreuve(epreuve,equipe , etat): Promise<boolean> {
    if (this.partieEquipes) {
      this.partieEquipes?.findOneAndUpdate({
        'les_epreuves.nom_epreuve': epreuve, 'nom_partie': this.partie, 'nom_equipe':  equipe
      },
        { $set: { 'les_epreuves.$.etat': etat } }
      )


        // this.user?.functions.ChangeDifficulte(idPartie , nomEpreuve , difficulte)

        .then(result => {

          console.log(`et, fantôme sans os `);
          return Promise.resolve(true);
        })
        .catch(err => console.error(`Failed to add review: ${err}`  )    )

    }
    else { console.log('Par les ombres myrteux'); return false; }
    //throw 'Not connected de setStatutJoueur';
  }
/*
  public setLejoueur(lj: LeJoueur) {
    console.log(`lj equipe: ${lj.equipe}`);
    this.leJoueur.next(lj);
    console.log(`lejoueur equipe: ${this.leJoueur.value.equipe}`);
  }

*/


  async isJoueurActif(joueur: string, equipe: string): Promise<boolean> {
    if (this.partieEquipes) {

      this.partieEquipes?.findOne({
        'les_joueurs.nom_joueur': joueur, 'nom_partie': this.partie, 'nom_equipe': equipe
      }
      )


        // this.user?.functions.ChangeDifficulte(idPartie , nomEpreuve , difficulte)

        .then(result => {

          console.log(`je prendrai mon repos`);
          return Promise.resolve(true);
        })
        .catch(err => console.error(`Failed to add review: ${err}`))

    }
    else { console.log('Vous serez au foyer'); return Promise.resolve(false); }
    //throw 'Not connected de setStatutJoueur';
  }
public getJoueurEnCours():number{
   console.log(`une vieille accroupie`);
   
    return Number(sessionStorage.getItem('numJoueur'));
    
  
}

public setJoueurEnCours(j:number) {
  sessionStorage.setItem('numJoueur', j.toString());
  // this.joueurEncours = j;
}
  
async valideMdP(nom : string , mdp : string): Promise<string> {
   
  const myArgs = [this.partie ,nom, mdp];
  if (this.user) {
    return this.user.functions.ValidationMdP(this.partie ,nom, mdp).then((result) => {
      console.log(`Regrettant mon amour`);
      if(result){
      return Promise.resolve( result);
      }else{
        return "KO";
      }
    });
  }
  throw 'Not connected';
}
}
