
<app-entete epreuve="voici"></app-entete> 

<div id=tout>
  <div id="gauche"> 
   

    <div id= "corpsAC"   class="box">

      <audio  id=audioepreuve src="assets/voici/voici4.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici8.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici7.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici1.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici11.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici6.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici9.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici2.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici3.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici10.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici5.mp3"   controls >  </audio> 
      <audio  id=audioepreuve src="assets/voici/voici12.mp3"   controls >  </audio> 
    
   
   </div>

  </div> <!-- fin gauche -->
  <div  id=droite>  
    

   <div class=corps> 
    
    <div class= "tableMenu"  >
      <div class="elmtMenu1" >    <p class= "txtTitre">ACTU</p>    </div> 
      <div class="elmtMenu">  <p class= "txtTitre">PEOPLE</p>   </div>
      <div class="elmtMenu">  <p class= "txtTitre">BEAUTÉ</p>   </div>
      <div class="elmtMenu">  <p class= "txtTitre">MODE</p>   </div>
      <div class="elmtMenu">  <p class= "txtTitre">TÉLÉ</p>   </div>
      <div class="elmtMenu">  <p class= "txtTitre">VIDÉOS</p>   </div>
      <div class="elmtMenu">   <p class= "txtTitre">BIOS DE STARS</p>   </div>
      <div class="elmtMenu2">  <p class= "txtTitre">HUMOUR</p>   </div>
      
  </div> <!-- fin tableMenu-->
  <div class="contenuPrinc">
  <div id="divTopNews" class= "txtNormal">
       
        <span style="color:#53bdbd;" >Les tops news </span> 
       <span style="color:black;font-style:italic;" >du jour </span>  
 
  
  </div>    
  
  <div id="lesprogrammes"  >
    <div class="imageAfficheHC">   <img id="afficheHC" src="/assets/voici/A-qui-sont-ces-voix.jpeg"  border="0"   /> </div>
    <div id= "programme"     >
    
        <div id="actions"class= "txtNormal" >
          <span style="color:#ed1b32;font-size: 12pt;" > <strong >« À qui sont ces voix ? » </strong></span> <br /> 
             <p>  <span style="color:black;font-style:italic;font-size: 14pt;"><strong >Scoop ! </strong></span>   
                <span style="color:black" >Des hackers tenteraient de pirater notre site Voici.fr. 
                  Heureusement, nous sommes protégés par notre toute nouvelle 
                  sécurité ! Pour la franchir il faut réussir à télécharger les documents 
                  audios et images qui leurs permettraient en échangeant leurs 
                  informations de trouver un mot de 4 lettres. En effet il y a 4 célébrités 
                  à trouver. Chacune a une voix différente. À eux de trouver à qui elle 
                  appartient en écoutant les affirmations de ces dernières… ce n’est pas 
                  gagné ! </span> 
            
        </div>  <!--fin actions -->
      </div> <!--fin  programme -->  
</div> <!--fin lesprogrammes --> 
<div class= "tablejoueurs"  >
  <div  id= "tableUnJoueur" style ="display: table; clear: both;" >
      <div class="img1Joueur"> 
      <span><img class="imgAct" src="/assets/voici/Selfie.jpeg"  border="0"  (click)="afficherIndice('noir')"/></span>
      </div>
      <div class= "acteursSsTitre1"     > 
      <p>  Savez-vous bien prendre un 
        Selfie ? </p>
      </div>
      <div class= "acteursSsTitre2"    > 
          <p>  Prise juste avant sa séance de 
            sport avec son coach, cette photo 
            s’ajoute aux nombreux clichés 
            avantageux postés sur son compte 
            Twitter. A force de se balader sur 
            la plage, faire du yoga, et poser au 
            naturel il y a de quoi être envieuse... </p>
      </div>
  </div>

  <div  id= "tableUnJoueur" style ="display: table; clear: both;" >
    <div class="img1Joueur"> 
    <span><img class="imgAct" src="/assets/voici/Cellulate.jpeg"  border="0"  (click)="afficherIndice('blanc')"/></span>
    </div>
    <div class= "acteursSsTitre1"     > 
    <p>  Cellulate - Nouvelle crème 
      de jour </p>
    </div>
    <div class= "acteursSsTitre2"    > 
        <p>  La carotte se révèle aussi efficace 
          contre les rides. Et pour ça, on dit 
          merci à la bêta-carotène qu’elle 
          contient, un puissant antioxydant 
          de la famille des provitamines A, 
          qui lutte contre le vieillissement 
          cutané... </p>
    </div>
</div>
  <div  id= "tableUnJoueur" style ="display: table; clear: both;" >
      <div class="img1Joueur"> 
          <span><img class="imgAct"   src="/assets/voici/Animaux-de-noel.jpeg"  border="0"  (click)="afficherIndice('rouge')"/></span>
      </div>
      <div class= "acteursSsTitre1"     > 
      <p>  Animaux de Noël</p>
      </div>
      <div class= "acteursSsTitre2"     > 
          <p>  S’il y a une cause qui tient 
            particulièrement à cœur à Michel 
            Drucker, ce sont les animaux. Et voir 
            les appels de détresse du personnel 
            des refuges l’a complètement 
            bouleversé. C’est la raison pour 
            laquelle, l’animateur a indiqué avoir 
            lancé une pétition... </p>
      </div>
  </div>
  <div  id= "tableUnJoueur" style ="display: table; clear: both;" >
      <div class="img1Joueur"> 
          <span><img class="imgAct" src="/assets/voici/Covid-et-confinement.jpeg"  border="0"  (click)="afficherIndice('gris')"/></span>
      </div>
      <div class= "acteursSsTitre1"    > 
      <p>  Mode : Covid et confinement </p>
      </div>
      <div class= "acteursSsTitre2"     > 
          <p>  En moins d’un an, le port du 
            masque de protection est devenu 
            la norme, et on a bien compris 
            que c’est parti pour durer... Alors 
            essayons de positiver et de faire 
            de cet accessoire contraignant, 
            un atout style. Et si la bonne idée, 
            c’était de coordonner son masque... </p>
      </div>
  </div>
  


</div> <!-- fintableJoueurs -->
</div> <!-- fin contenuPrinc -->
</div> <!-- fin corps -->
</div> <!-- fin droite -->

 
</div>  <!-- fin tout -->
<!--
    <div id= "tablejoueurs"  >
    
      <span><img id="imgAct" class="liens" src="assets/transparent.png"  border="0"  (click)="afficherIndice('noir')"/></span>
      <span><img id="imgAct" class="liens" src="assets/transparent.png"  border="0"  (click)="afficherIndice('blanc')"/></span>
      <span><img id="imgAct" class="liens" src="assets/transparent.png"  border="0"  (click)="afficherIndice('rouge')"/></span>
      <span><img id="imgAct" class="liens" src="assets/transparent.png"  border="0"  (click)="afficherIndice('gris')"/></span>

       
   

    </div>

</div>
-->
 
