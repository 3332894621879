
 
<div class="message">
<div *ngIf="resultat=='OK'; else elseBlock"><img class="imgReussi" src="/assets/telechargement/accepte.png"  border="0" /></div>
<ng-template #elseBlock><img class="imgRefuse" src="/assets/telechargement/refuse.png"  border="0"  /></ng-template>

<br><br> 
</div>
 
<p ><a (click)="suite()" id="retour">{{texteRetour}}</a></p>
 