<!--
<div id=barent>
    <div id=divBtLogin>  <input id= "btLogin" type = "submit" value = "s'identifier" (click)="login()"/></div> 
     
   <div id=divChrono class="chronometre">
      <div class="tim">
         <span >{{h}}h</span> :
         <span >{{mn}}m</span> :
         <span >{{s}}s</span> 
      </div>
    </div>
 
   
  </div>
-->
<div id="wholeComponent">
  <div id=titre>
    Hack Chicken 
  </div>
<div *ngIf="afficherLogin"  class = divlogin >
  <div *ngIf="afficherJoueur"    >
   
  <div>
Merci de saisir le nom du joueur et son mot de passe
</div>
 
<div>
  <input type="text" #joueurSaisi    id="username-field" class="login-form-field" placeholder="Joueur">
  
</div>
<div> 
  <input type="text" #mdpSaisi    id="pwdname-field" class="login-form-field" placeholder="Mot de passe">
  
   
</div> 
<div>
<input type="button" value="Envoyer" id="login-form-submit" (click)="verifLogin(this)">
</div>
</div> 

<div *ngIf="afficherSalle"   >
   
  <div>
Merci de saisir la salle et la couleur du joueur
</div>
<div   id="saisiesalle"> 
<div>
<!--  <input type="text" #salleSaisi    id="equipe-field" class="login-form-field" placeholder="equipe">  -->
  <a  ><select id="salleSaisi"  #salleSaisi     >
               
            <option *ngFor="let equipe of nomsEquipes" [value]="equipe"   > 
       
              {{ equipe  }}
      </option>
    </select> </a>
  
</div>
<div> 
<!--  <input type="text" #couleurSaisi    id="couleur-field" class="login-form-field" placeholder="couleur"> -->
<a  ><select id="couleurSaisi"  #couleurSaisi  >
               
  <option *ngFor="let couleur of nomsCouleurs" [value]="couleur"   > 

    {{ couleur  }}
</option>
</select> </a> 
   
</div> 
<div>
<input type="button" value="Envoyer" id="login-form-salle-submit" (click)="verifLoginSalle(this)">
</div>
</div>
</div>
</div>


 <div id=continuer>
<div  *ngIf="afficherContinuer" id=divJoueurPrinc>
 continuer avec   <input id= "btjoueur" type = "submit" value = {{nomjoueur}} (click)="continuer()"/> 
</div>

<div *ngIf="afficherRemplacer"  id=divJoueur2    > 
 Remplacer    <input id= "btjoueur2" type = "submit" value ={{joueurRemplace.nom_joueur}} (click)="remplacer()"/>
</div>
</div>
</div>