import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';
import { Equipe, EpreuveDeEquipe, PartieEquipe } from '../models/partie.model';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {
  lesequipes: PartieEquipe[];
  slesEquipes: Subscription;
  tabEpreuves = new Array(); 
   tabRangEpreuves   = {
     "epreuves":[
         "google" ,
        "babbel"   ,
       "netflix"  ,
       "voici"   ,
       "spotify"   ,
       "wikipedia"     ,
       "youtube"   ,
       "marmiton"  ,
       "twitch"   ,
      "allocine"    
     ],
     "rangs":[
       0 ,1 , 2, 3, 4 ,5 ,6, 7, 8, 9
     ]
   }

  constructor(private realmInterface: RealmInterfaceService,private router: Router ) { }

  ngOnInit(): void {

    this.slesEquipes = this.realmInterface.lesEquipes.subscribe(x => {
      if (x.length === 0) return;  // cas particulier du début où le tableau est vide faute d'avoir reçu quelque chose.
      this.lesequipes = x;
      this.alimTabEpreuves()
    });

    this.realmInterface.lanceWatch(); 
  }
  ngOnDestroy(): void {
     
    this.slesEquipes.unsubscribe();
  }
  backtohome()  { this.router.navigateByUrl("home");}
  public donneUrlChapeau(): string
  {
     let currenthackerCouleur = localStorage.getItem('hackerCouleur');
      return `assets/chapeau${currenthackerCouleur}.png`;
  }
  alimTabEpreuves(){
    for( let i= 0; i < this.lesequipes.length; i++)
    {
      let epr = new Array();
      epr[0] = this.lesequipes[i].nom_equipe;
      for( let j= 0; j < this.lesequipes[i].les_epreuves.length; j++)
      {
        let epreuve = this.lesequipes[i].les_epreuves[j];
        let rang = this.rangEpreuve(epreuve.nom_epreuve);
        epr[rang+ 1] = " ";
        if(epreuve.etat == "reussie"){
        epr[rang+ 1] = "R";
      }
        console.log(`epreuve rang : ${epreuve.nom_epreuve }   /  ${rang}   `);
        
      }
      this.tabEpreuves[i]  = epr;
      console.log(`tabEpreuves[i][rang] : ${this.tabEpreuves[i][0] }   ${this.tabEpreuves[i][1] }  ${this.tabEpreuves[i][2]}`);
    }
  }
  rangEpreuve(ep : string){
    for( let i= 0; i < this.tabRangEpreuves.epreuves.length; i++)
    {
      console.log(`tabRangEpreuves[i][0] : ${this.tabRangEpreuves.epreuves[i]  }  /  ${this.tabRangEpreuves.rangs[i]  }  `);
      
      if(this.tabRangEpreuves.epreuves[i] == ep){
      return this.tabRangEpreuves.rangs[i];
      }
      
    }
    return 0;
}

public donneUrlLogo(epr : string): string
  {
     
      return `/assets/logosepreuves/${epr}.png`;
    
    }
}
