<div id="wholeComponent" [ngClass]="{'withBackground': this.showBackGround, 'withBottomBorder': this.showBackGround===false}"
  fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <img id="logojoueur" src="{{chapeau}}" />
    <img *ngIf="epreuve !== ''" id="logoepreuve" src="{{this.donneUrlLogo()}}" />  
  </div>
  <span *ngIf="showCode && !indiceMode" id="spanCode"  style= "color: #4de9e8; font-weight: bold;">
    Code :
    <input type="text" #code   name = "Inputcode"     maxlength = "10" style= "border-radius: 15px;"
                       (keyup)="validerCode( $event)"   /> 
  </span>
  <img id="logohome" src="assets/Profil.png" (click)="backtohome()" />
</div>