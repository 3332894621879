import { Component, OnInit , ViewChild ,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { RealmInterfaceService } from '../realm-interface.service';


@Component({
  selector: 'app-spotify',
  templateUrl: './spotify.component.html',
  styleUrls: ['./spotify.component.scss']
})


export class SpotifyComponent implements  OnInit {
  
  @ViewChild('audioComp')
  audioPlayer : HTMLAudioElement;
  audioVisible : boolean =  false ; 
   
  constructor(private router: Router , private realmInterface: RealmInterfaceService) {
    localStorage.setItem('epreuve', 'spotify');
   }
 
   toggleAudio(event: any) {
     
    this.audioPlayer.play();
}
 
  ngOnInit(): void {
  }

  afficherIndice(joueur : string)
  {
    let hackerCouleur = localStorage.getItem('hackerCouleur');
    if(this.realmInterface.getJoueurEnCours()!=0){// on est sur le joueur remplace
      hackerCouleur = localStorage.getItem('hackerCouleur2');}
    let url = `indicedeuximages/spotify`;
    if(joueur == hackerCouleur) {this.router.navigateByUrl(url);}
  }
  ecouterConsignes(){
    if(this.audioVisible){
      this.audioVisible = false;
    }else{
      this.audioVisible = true;
    }
  }
}
  