<div id="wholeComponent" fxLayout="column">
  <app-entete [showBackGround]="false" [showCode]="false"></app-entete>
  <div class="elmtTableMA">
    <h2>Les epreuves par equipe</h2>
    <table id="tablepreuvesquipes" class="tableau" border="1">
      <tr>
        <th> </th>
        <th *ngFor="let epr  of tabRangEpreuves.epreuves ">
          <div class="DivImageEpreuve"> <img class="imgEpreuve" src="{{this.donneUrlLogo(epr)}}" border="0" /> </div>
        </th>
      </tr>
      <tr *ngFor="let equEpreuves of tabEpreuves ">

        <td *ngFor="let epr  of equEpreuves ">

          <a>{{epr}}</a>
        </td>

      </tr>
    </table>
  </div>
</div>